import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Login from "../Login";
import SingUp from "../SingUp";
import ForgotPassword from "./ForgotPassword";
import secureLocalStorage from "react-secure-storage";
import { BASE_URL } from "../../config";


const Head = () => {
    const user_token = secureLocalStorage.getItem("user_token");
    const [ContactDetail, setContactDetail] = useState([]);
    useEffect(() => {
        fetch(`${BASE_URL}/api/WebDashboard/GetContactUs`, {
            headers: {
                Authorization: `Bearer ${user_token}`
            },
        }).then(response => response.json()).then(result => setContactDetail(result));
    }, []);
    return (
        <>


            <>
                {/* head start here */}
                <section className="head_nav">
                    <div className="container">
                        <div className="row d-flex align-items-center justify-content-between">
                            <div className="col-md-6 head_nav_left">
                                {
                                    ContactDetail[0] ? (
                                        <>
                                            <a href="#">
                                                <p className="text-white">
                                                    <i className="fa-solid lni lni-mobile" /> {ContactDetail[0]["Mobile"]}
                                                </p>
                                            </a>

                                            <a href={"mailto:" + ContactDetail[0]["Email"]}>
                                                <p className="text-white">
                                                    <i className="fa-solid lni lni-envelope two" /> {ContactDetail[0]["Email"]}
                                                </p>
                                            </a>
                                        </>
                                    ) : null
                                }

                            </div>
                            <div className="col-md-6 head_nav_right">
                                {
                                    ContactDetail[0] ? (
                                        <>
                                            <a href={ContactDetail[0]["Facebook"]}>
                                                <i className="fa-brands lni lni-facebook" />
                                            </a>
                                            <a href={ContactDetail[0]["Twitter"]}>
                                                <i className="fa-brands lni lni-twitter" />
                                            </a>
                                            <a href="#">
                                                <i className="fa-brands lni lni-youtube" />
                                            </a>
                                            <a href="#">
                                                <i className="fa-brands lni lni-instagram" />
                                            </a>
                                        </>
                                    )
                                        : null
                                }
                                <Link className="btn btn_login" id="openLoginPopUp" data-bs-toggle="modal"
                                    data-bs-target="#exampleModal">
                                    Login
                                </Link>
                                <a href="#" className="btn btn-warning download_btn">
                                    Apply Now
                                </a>
                                <a href="#" className="btn btn-warning ms-2 download_btn">
                                    Download App
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* head end here */}



                {/* Modal */}
                <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Log In</h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <Login />
                                <p className="mt-3 mb-0 text-center">
                                    <Link data-bs-toggle="modal" data-bs-target="#ForgotPassword">
                                        Forgot Password?
                                    </Link>
                                </p>
                                <p className="mt-0 text-center">
                                    Not a user?
                                    <Link data-bs-toggle="modal" data-bs-target="#SignupModal" className="ms-1">
                                        Create new account
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Signup Modal */}
                <div
                    className="modal fade"
                    id="SignupModal"
                    tabIndex={-1}
                    aria-labelledby="SignupModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">SingUp</h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <SingUp />
                                <p className="mt-2 text-center">
                                    Aleardy Have an Account.
                                    <Link data-bs-toggle="modal" data-bs-target="#exampleModal">Login</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Reset Password Modal */}
                <div
                    className="modal fade"
                    id="ForgotPassword"
                    tabIndex={-1}
                    aria-labelledby="ForgotPasswordLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">SingUp</h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <ForgotPassword />
                                <p className="mt-2 text-center">
                                    Go Back To
                                    <Link data-bs-toggle="modal" data-bs-target="#exampleModal" className="ms-1">Login</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </>

        </>
    )
}

export default Head;