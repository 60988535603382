import React from "react";
import Head from "./component/Head";
import Menu from "./component/Menu";
import Footer from "./component/Footer";
import Client from "./component/Client";
import BlogSection from "./component/BlogSection";
import Testimonial from "./component/Testimonial";
import TeacherSection from "./component/TeacherSection";
import Counter from "./component/Counter";
import EventSection from "./component/EventSection";
import CourseSection from "./component/CourseSection";
import Why from "./component/Why";
import Video from "./component/Video";
import Category from "./component/Category";
import Crousel from "./component/Crousel";
import AboutSection from "./component/AboutSection";

const HomePage = () => {
    return (
        <>
            <Crousel />
            <AboutSection />
            <Category />
            <Why />
            <Video />
            <CourseSection />
            
            <EventSection />
            <Counter />
            <TeacherSection />
            <Testimonial />
            <BlogSection />
            <Client />
        </>
    )
}

export default HomePage;