import { Link } from "react-router-dom";

function Header() {
    return (
        <header>
            <div className="topbar d-flex align-items-center bg-theme shadow-none border-light-2 border-bottom">
                <nav className="navbar navbar-expand">
                    <div className="mobile-toggle-menu text-white me-3"><i className='bx bx-menu'></i>
                    </div>
                    <div className="top-menu-left d-none d-lg-block">
                        <ul className="nav">
                            {/* <li className="nav-item">
                                <a className="nav-link text-white" href="app-emailbox.html"><i className='bx bx-envelope'></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="app-chat-box.html"><i className='bx bx-message'></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="app-fullcalender.html"><i className='bx bx-calendar'></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="app-to-do.html"><i className='bx bx-check-square'></i></a>
                            </li> */}
                        </ul>
                    </div>
                    <div className="search-bar flex-grow-1">
                        <div className="position-relative search-bar-box">
                            <form>
                                <input type="text" className="form-control search-control" autoFocus placeholder="Type to search..." /> <span className="position-absolute top-50 search-show translate-middle-y"><i className='bx bx-search'></i></span>
                                <span className="position-absolute top-50 search-close translate-middle-y"><i className='bx bx-x'></i></span>
                            </form>
                        </div>
                    </div>
                    <div className="top-menu ms-auto">
                        <ul className="navbar-nav align-items-center">
                          
                        </ul>
                    </div>
                    <div className="user-box dropdown border-light-2">
                        <button style={{ border: "none" }} className="bg-theme d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="assets/images/avatars/avatar-2.png" className="user-img" alt="user avatar" />
                            <div className="user-info ps-3">
                                <p className="user-name mb-0 text-white">Pauline Seitz</p>
                                <p className="designattion mb-0">Web Designer</p>
                            </div>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li><a className="dropdown-item" href="#!"><i className="bx bx-user"></i><span>Profile</span></a>
                            </li>
                            <li><a className="dropdown-item" href="#!"><i className="bx bx-cog"></i><span>Settings</span></a>
                            </li>
                            <li><a className="dropdown-item" href="#!"><i className='bx bx-home-circle'></i><span>Dashboard</span></a>
                            </li>
                            <li><a className="dropdown-item" href="#!"><i className='bx bx-dollar-circle'></i><span>Earnings</span></a>
                            </li>
                            <li><a className="dropdown-item" href="#!"><i className='bx bx-download'></i><span>Downloads</span></a>
                            </li>
                            <li>
                                <div className="dropdown-divider mb-0"></div>
                            </li>
                            <li><Link className="dropdown-item" to="/logout"><i className='bx bx-log-out-circle'></i><span>Logout</span></Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header;