import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { bindActionCreators } from "redux";
import { actionCreator } from "../STATES";

export default function Logout() {
    const Dispatch = useDispatch();
    const { LoginAction } = bindActionCreators(actionCreator, Dispatch);
    const navigate = useNavigate();

    secureLocalStorage.removeItem("adm_username");
    secureLocalStorage.removeItem("adm_password");
    secureLocalStorage.removeItem("adm_role");
    secureLocalStorage.removeItem("adm_name");
    secureLocalStorage.removeItem("adm_token");

    LoginAction(false);

    navigate("/", { replace: true });
    return (
        <h1>Logged Out!!</h1>
    )
}