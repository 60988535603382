import { Link } from "react-router-dom";

function StudentProfile() {
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Student Profile</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link href="/"><i className="bx bx-home-alt"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Student Profile</li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                    <div className="container">
                        <div className="main-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-column align-items-center text-center">
                                                <img src="assets/images/avatars/avatar-2.png" alt="Admin" className="rounded-circle p-1 bg-primary" width="110" />
                                                <div className="mt-3">
                                                    <h4>John Doe</h4>
                                                    <p className="text-secondary mb-1">Full Stack Developer</p>
                                                    <p className="text-muted font-size-sm">Bay Area, San Francisco, CA</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb-3">
                                                <div className="col-sm-4">
                                                    <h6 className="mb-0">Full Name</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    <p>Demo Text</p>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-sm-4">
                                                    <h6 className="mb-0">Email</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    <p>Demo Text</p>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-sm-4">
                                                    <h6 className="mb-0">Phone</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    <p>Demo Text</p>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-sm-4">
                                                    <h6 className="mb-0">Mobile</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    <p>Demo Text</p>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-sm-4">
                                                    <h6 className="mb-0">Address</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary">
                                                    <p>Demo Text</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudentProfile;