import React from 'react';

function PolicyPage() {
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h3 style={styles.heading}>Privacy & Policy</h3>
                <h3 style={styles.instituteName}>Institute of Computer Education (ICE)</h3>
            </div>
            <p style={styles.subHeading}>Effective Date: 01 Jan 2023</p>
            <div style={styles.content}>
                <p style={styles.intro}>
                    At Institute of Computer Education (ICE), we are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you interact with our technical computer institute. By accessing our services or providing your information, you consent to the practices described in this policy.
                </p>
                {/* Rest of the Privacy Policy content */}
                <p style={styles.rights}>
                    Your Rights:<br />
                    You have the right to access, correct, update, or delete your personal information in our records. If you wish to exercise any of these rights or have any privacy-related concerns, please contact us using the information provided below.
                </p>
                {/* Contact information */}
                <p style={styles.changes}>
                    Changes to this Privacy Policy:<br />
                    We may update this Privacy Policy from time to time, and any changes will be posted on this page. We encourage you to review this Privacy Policy periodically for the latest information on our privacy practices.
                </p>
                {/* Contact information */}
                <div style={styles.contact}>
                    <p>
                        Contact Us:<br />
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:<br />
                        Email: teamumeinfo@gmail.com
                    </p>
                </div>
                {/* Agreement statement */}
                <p style={styles.agreement}>
                    By enrolling in our courses or using our services, you agree to the terms of this Privacy Policy.
                </p>
            </div>
        </div>
    );
}

export default PolicyPage;

// Styles
const styles = {
    container: {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f7f7f7',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        fontSize: '28px',
        fontWeight: 'bold',
        marginBottom: '10px',
        color: '#333',
    },
    instituteName: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#555',
    },
    subHeading: {
        fontWeight: 'bold',
        color: '#555',
    },
    content: {
        marginTop: '20px',
    },
    intro: {
        lineHeight: '1.6',
    },
    rights: {
        fontWeight: 'bold',
        marginTop: '20px',
    },
    changes: {
        fontWeight: 'bold',
        marginTop: '20px',
    },
    contact: {
        marginTop: '20px',
    },
    agreement: {
        fontWeight: 'bold',
        marginTop: '20px',
        color: '#008000',
    },
};
