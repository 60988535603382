function PrivacyPage(){
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h3 style={styles.heading}>Terms & Conditions</h3>
                <h3 style={styles.instituteName}>Institute of Computer Education (ICE)</h3>
            </div>
            <p style={styles.subHeading}>Effective Date: 01 Jan 2023</p>
            <div style={styles.content}>
                <p style={styles.intro}>
                    Welcome to Institute of Computer Education (ICE). By using our services, you agree to comply with and be bound by the following Terms & Conditions. Please read these carefully before using our website or enrolling in our courses.
                </p>
                <p style={styles.rights}>
                    User Conduct:<br />
                    You are responsible for ensuring that your use of our services and website does not violate any applicable laws or regulations and that you comply with these Terms & Conditions. You may not use our services for any illegal or unauthorized purpose.
                </p>
                <p style={styles.rights}>
                    Intellectual Property:<br />
                    The content and materials provided through our courses and website are the intellectual property of Institute of Computer Education (ICE) and are protected by copyright and other intellectual property laws. You may not reproduce, distribute, modify, or create derivative works of our content without our prior written consent.
                </p>
                <p style={styles.rights}>
                    Limitation of Liability:<br />
                    Institute of Computer Education (ICE) shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use of our services or website. We do not guarantee the accuracy, completeness, or reliability of any content or materials provided.
                </p>
                <p style={styles.rights}>
                    Changes to Terms & Conditions:<br />
                    We reserve the right to modify or update these Terms & Conditions at any time without prior notice. The updated version will be effective as of the date of posting on our website. It is your responsibility to review these Terms & Conditions periodically.
                </p>
                <div style={styles.contact}>
                    <p>
                        Contact Us:<br />
                        If you have any questions or concerns about these Terms & Conditions or our services, please contact us at:<br />
                        Email: teamumeinfo@gmail.com
                    </p>
                </div>
                <p style={styles.agreement}>
                    By using our services, you agree to the terms of these Terms & Conditions.
                </p>
            </div>
        </div>
    )
}


export default PrivacyPage;

// Styles (Same styles as Privacy Policy)
const styles = {
    container: {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f7f7f7',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        fontSize: '28px',
        fontWeight: 'bold',
        marginBottom: '10px',
        color: '#333',
    },
    instituteName: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#555',
    },
    subHeading: {
        fontWeight: 'bold',
        color: '#555',
    },
    content: {
        marginTop: '20px',
    },
    intro: {
        lineHeight: '1.6',
    },
    rights: {
        fontWeight: 'bold',
        marginTop: '20px',
    },
    contact: {
        marginTop: '20px',
    },
    agreement: {
        fontWeight: 'bold',
        marginTop: '20px',
        color: '#008000',
    },
};