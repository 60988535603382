import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { BASE_URL } from "../../config";

function ManageCenter() {
    const adm_token = secureLocalStorage.getItem("adm_token");
    const [Centers, setCenters] = useState([]);
    const baseUrl = `${BASE_URL}/api/`;


    useEffect(() => {
        fetch(`${baseUrl}StudyCenter/GetStudyCenterfordropdown`, {
            headers: {
                Authorization: `Bearer ${adm_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setCenters(result);
            });
        });
    }, [adm_token]);
    
    function deleteCenter(OID) {
        fetch(`${baseUrl}StudyCenter/PostDeleteStudyCenter?OId=${OID}`, {
            headers: {
                Authorization: `Bearer ${adm_token}`
            },
            method: "DELETE"
        });
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">

                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Study Center</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/"><i className="bx bx-home-alt"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Center</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Centers.length ? Centers.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{data["KRCName"]}</td>
                                                            <td>
                                                                <Link to={`/admin/edit-center/${data["OId"]}`} className="btn btn-outline-primary btn-sm mx-1 pt-0 px-1"><i className="lni lni-pencil me-0"></i></Link>
                                                                <button type="button" onClick={() => deleteCenter(data["OId"])} className="btn btn-outline-danger btn-sm pt-0 px-1"><i className="lni lni-trash me-0"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageCenter;