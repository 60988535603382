import React from "react";

const AboutSection = () => {
    return (
        <>
            {/* about start here */}
            <section className="ptb_70">
                <div className="container">
                    <div className="row about_top">
                        <div className="col-md-5">
                            <img src="./images/about.png" className="img-fluid" />
                        </div>
                        <div className="col-md-7 d-flex align-items-center">
                            <div className="about_left">
                                <h2 className="mb-3">About Us</h2>
                                <p>
                                ICE (Institute of Computer Education) is a renowned computer institute that provides excellent education and training in the field of computer science and technology. It is located in a prime location and has state-of-the-art infrastructure and facilities to ensure a high-quality learning experience for its students.
                                </p>
                                <p>
                                The faculty at ICE is highly qualified and experienced, with a deep understanding of the latest developments and trends in the field of computer science. They are dedicated to providing personalized attention to each student and ensuring that they receive the best possible education and training.
                                </p>
                                <div className="mt-3">
                                   
                                </div>
                            </div>
                        </div>
                        <div className="about_border"></div>
                    </div>
                </div>
            </section>
            {/* about end here */}
        </>

    )
}

export default AboutSection;