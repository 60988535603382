import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
    AddCenter, AddCourse, AddSession, AddStaff,
    AddStudents, AddSubject, Home, ManageAttendance,
    ManageCenter, ManageCourse, ManageSession, ManageStaff,
    ManageStudents, ManageSubject, StudentProfile, Footer,
    Header, Sidebar, About, Blog, Certificate,
    Contact, Course, Event, HomePage, Teacher,
    HomeFooter, Head, Menu, SingUp, PolicyPage, Login, Logout, PrivacyPage
} from "./Pages"

import { useDispatch, useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators } from "redux";
import { actionCreator } from "./STATES";
import { BASE_URL } from "./config";


function App() {
    const dispatch = useDispatch();
    const { LoginAction } = bindActionCreators(actionCreator, dispatch);
    const LoginState = useSelector(state => state.login);

    const getUserToken = async () => {
        const requestBody = {
            username: "help.icecampus@gmail.com",
            password: "abc@123",
            CompanyID: "UMe"
        };
    
        try {
            const response = await fetch(`${BASE_URL}/api/Login/Login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Use JSON format
                },
                body: JSON.stringify(requestBody), // Send JSON body
            });
    
            // Check if the response is okay
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const result = await response.json();
            console.log('Response:', response);
            secureLocalStorage.setItem("user_token", result["data"]);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };
    
    getUserToken();

    if (!LoginState && secureLocalStorage.getItem("adm_username")) {
        const adm_username = secureLocalStorage.getItem("adm_username");
        const adm_password = secureLocalStorage.getItem("adm_password");
        const adm_role = secureLocalStorage.getItem("adm_role");

        const formData = new URLSearchParams();
        formData.append('CompanyID', 'UMe');
        formData.append('username', adm_username);
        formData.append('password', adm_password);

        fetch(`${BASE_URL}/api/Login/Login`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                return response.json();
            })
            .then(result => {
                if (result["access_token"]) {
                    const adm_token = result["access_token"];
                    fetch(`${BASE_URL}/api/Account/GetCheckRole?Email=${adm_username}`, {
                        headers: {
                            Authorization: `Bearer ${adm_token}`,
                        }
                    }).then(response => {
                        response.json().then(result => {
                            if (result.length) {
                                secureLocalStorage.setItem("adm_token", adm_token);
                                LoginAction(true);
                            }
                        })
                    })
                } else {
                    secureLocalStorage.removeItem("adm_username");
                    secureLocalStorage.removeItem("adm_password");
                    secureLocalStorage.removeItem("adm_role");
                    secureLocalStorage.removeItem("adm_name");
                    secureLocalStorage.removeItem("adm_token");

                    LoginAction(false);

                }
            })
    }


    return (
        <>
            <BrowserRouter>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <div className="wrapper">

                    <HeadSection />
                    <Routes>

                        <Route path="/" element={<HomePage />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/course" element={<Course />} />
                        <Route path="/event" element={<Event />} />
                        <Route path="/teacher" element={<Teacher />} />
                        <Route path="/privacy" element={<PrivacyPage />} />
                        <Route path="/policy" element={<PolicyPage />} />
                        <Route path="/certificate" element={<Certificate />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<SingUp />} />


                        {/* Admin Routes */}
                        {LoginState ? (
                            <>
                                <Route path="/logout" element={<Logout />} />


                                <Route path="/admin/dashboard" element={<Home />} />
                                <Route path="/admin/" element={<Home />} />
                                <Route path="/admin/manage-center" element={<ManageCenter />} />
                                <Route path="/admin/add-center" element={<AddCenter />} />
                                <Route path="/admin/edit-center/:id" element={<AddCenter />} />

                                <Route path="/admin/manage-students" element={<ManageStudents />} />
                                <Route path="/admin/edit-student/:id" element={<AddStudents />} />

                                <Route path="/admin/manage-course" element={<ManageCourse />} />
                                <Route path="/admin/add-course" element={<AddCourse />} />
                                <Route path="/admin/manage-staff" element={<ManageStaff />} />
                                <Route path="/admin/add-staff" element={<AddStaff />} />
                                <Route path="/admin/manage-session" element={<ManageSession />} />
                                <Route path="/admin/add-session" element={<AddSession />} />
                                {/* <Route path="/admin/attendance" element={<Attendance />} /> */}


                                {/* Center Login */}
                                <Route path="/admin/manage-student" element={<ManageStudents />} />
                                <Route path="/admin/add-student" element={<AddStudents />} />
                                <Route path="/admin/manage-subject" element={<ManageSubject />} />
                                <Route path="/admin/add-subject" element={<AddSubject />} />
                                <Route path="/admin/manage-staff" element={<ManageStaff />} />
                                <Route path="/admin/add-staff" element={<AddStaff />} />
                                <Route path="/admin/manage-attendance" element={<ManageAttendance />} />


                                {/* Student Login */}
                                <Route path="/admin/profile" element={<StudentProfile />} />
                            </>
                        ) : null}



                        <Route path="*" element={<HomePage />} />
                    </Routes>
                    <FootSection />
                </div>
            </BrowserRouter>
        </>
    );
}


function HeadSection() {
    const location = useLocation();
    const LoginState = useSelector(state => state.login);

    if (location.pathname.includes("/admin") && LoginState) {
        return (
            <>
                <Header />
                <Sidebar />
            </>
        )
    } else {
        return (
            <>
                <Head /><Menu />
            </>
        )
    }
}


function FootSection() {
    const location = useLocation();
    const LoginState = useSelector(state => state.login);

    if (location.pathname.includes("/admin") && LoginState) {
        return (
            <>
                <Footer />
            </>
        )
    } else {
        return (
            <>
                <HomeFooter />
            </>
        )
    }
}
export default App;
