import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import { BASE_URL } from "../../config";

const Category = () => {
    const [courseCategory, setcourseCategory] = useState([]);
    const user_token = secureLocalStorage.getItem("user_token");
    useEffect(() => {
        fetch(`${BASE_URL}/api/Course/GetCourseCategory`, {
            headers: {
                Authorization: `Bearer ${user_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setcourseCategory(result);
            })
        })
    }, [user_token]);

    return (
        <>
            {/* popular categary start */}
            <section className="ptb_70 bg_blue">
                <div className="container">
                    <div className="text-center">
                        <h3 className="text-white">Most Popular Categories</h3>
                        <p className="mt-3 text-white">
                            If you are going to use a passage of Lorem Ipsum, you need to be sure
                            there <br /> isn't anything embarrassing hidden in the middle of text
                        </p>
                    </div>
                    <div className=" mt-5">
                        <Swiper
                            spaceBetween={50}
                            modules={[Scrollbar, A11y]}
                            scrollbar={{ draggable: true }}
                            slidesPerView={5}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {
                                courseCategory.length ? courseCategory.map((data, index) => {
                                    return (
                                        <SwiperSlide key={index} >
                                            <div className="col-md-12">
                                                <a >
                                                    <div className="card p-1">
                                                        <i className="lni lni-world" />
                                                        <h6 className="text-white text-center">{data.StdName}</h6>
                                                    </div>
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                    )
                                }) : null
                            }
                        </Swiper>

                    </div>
                </div>
            </section>
            {/* popular categary end */}
        </>


    )
}

export default Category;