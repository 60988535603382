import React from "react";

const BlogSection = () => {
    return (
        <>
            {/* blog start here */}
            <section className="bg_gray ptb_70">
                <div className="container">
                    <div className="text-center">
                        <h2>Our Blog</h2>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-4">
                            <a href="#"></a>
                            <div className="event_card blog_card">
                                <a href="#"></a>
                                <div className="event_card_head blog_card_head">
                                    <a href="#">
                                        <div className="event_card_head_abs">
                                            <img
                                                src="./images/blog_small_img1.jpg"
                                                className="img-fluid "
                                            />
                                        </div>
                                    </a>
                                    <div className="link">
                                        <a href="#"></a>
                                        <a href="#">
                                            <i className="fa-solid fa-link lni lni-link" />
                                        </a>
                                    </div>
                                </div>
                                <div className="event_card_body">
                                    <h6>Why are tickets to fly to Lagos expensive?</h6>
                                    <p className="mt-3">
                                        If you are going to use a passage of Lorem Ipsum you need to be
                                        sure there anything embarrassing hidden in the middle of text
                                    </p>
                                    <a href="#" className="btn btn-white p-0">
                                        Read More
                                    </a>
                                    <div className="d-flex mt-3 icon_sec">
                                        <p className="me-3">
                                            <i className="fa-solid fa-calendar-days text-warning" /> 15
                                            May, 2019
                                        </p>
                                        <p className="me-3">
                                            <i className="fa-solid fa-comments text-warning" /> 2 Comments
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <a href="#"></a>
                            <div className="event_card blog_card">
                                <a href="#"></a>
                                <div className="event_card_head blog_card_head">
                                    <a href="#">
                                        <div className="event_card_head_abs">
                                            <img
                                                src="./images/blog_small_img1.jpg"
                                                className="img-fluid "
                                            />
                                        </div>
                                    </a>
                                    <div className="link">
                                        <a href="#"></a>
                                        <a href="#">
                                            <i className="fa-solid fa-link lni lni-link" />
                                        </a>
                                    </div>
                                </div>
                                <div className="event_card_body">
                                    <h6>Why are tickets to fly to Lagos expensive?</h6>
                                    <p className="mt-3">
                                        If you are going to use a passage of Lorem Ipsum you need to be
                                        sure there anything embarrassing hidden in the middle of text
                                    </p>
                                    <a href="#" className="btn btn-white p-0">
                                        Read More
                                    </a>
                                    <div className="d-flex mt-3 icon_sec">
                                        <p className="me-3">
                                            <i className="fa-solid fa-calendar-days text-warning" /> 15
                                            May, 2019
                                        </p>
                                        <p className="me-3">
                                            <i className="fa-solid fa-comments text-warning" /> 2 Comments
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <a href="#"></a>
                            <div className="event_card blog_card">
                                <a href="#"></a>
                                <div className="event_card_head blog_card_head">
                                    <a href="#">
                                        <div className="event_card_head_abs">
                                            <img
                                                src="./images/blog_small_img1.jpg"
                                                className="img-fluid "
                                            />
                                        </div>
                                    </a>
                                    <div className="link">
                                        <a href="#"></a>
                                        <a href="#">
                                            <i className="fa-solid fa-link lni lni-link" />
                                        </a>
                                    </div>
                                </div>
                                <div className="event_card_body">
                                    <h6>Why are tickets to fly to Lagos expensive?</h6>
                                    <p className="mt-3">
                                        If you are going to use a passage of Lorem Ipsum you need to be
                                        sure there anything embarrassing hidden in the middle of text
                                    </p>
                                    <a href="#" className="btn btn-white p-0">
                                        Read More
                                    </a>
                                    <div className="d-flex mt-3 icon_sec">
                                        <p className="me-3">
                                            <i className="fa-solid fa-calendar-days text-warning" /> 15
                                            May, 2019
                                        </p>
                                        <p className="me-3">
                                            <i className="fa-solid fa-comments text-warning" /> 2 Comments
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-center mt-5">
                            <a href="#" className="btn btn-warning text-white py-2 px-5">
                                <b>
                                    View All Blog <i className="fa-solid fa-arrow-right ms-2" />
                                </b>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* blog end here */}
        </>

    )
};

export default BlogSection;