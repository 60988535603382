import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { BASE_URL } from "../../config";

const EventSection = () => {
    const [event, setEvent] = useState([]);
    const user_token = secureLocalStorage.getItem("user_token");
    useEffect(() => {
        fetch(`${BASE_URL}/api/WebDashboard/GetEvents`, {
            headers: {
                Authorization: `Bearer ${user_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setEvent(result);
            })
        })
    }, [user_token]);
    return (
        <>
            {/* event start here */}
            <section className="ptb_70 event_bg">
                <div className="container">
                    <div className="text-center">
                        <h2 className="text-white">Upcoming Events</h2>
                    </div>
                    <div className="row mt-4">
                        {
                            event.length ? event.map((data, index) => {
                                return (
                                    <div key={index} className="col-md-4">
                                        <a href="#">
                                            <div className="event_card">
                                                <div className="event_card_head">
                                                    <div className="event_card_head_abs">
                                                        <img
                                                            src="./images/event_img1.jpg"
                                                            className="img-fluid "
                                                        />
                                                    </div>
                                                    <div className="date">
                                                        <div className="pt-2 px-2">
                                                            <h6>{data.StartDate}</h6>
                                                        </div>
                                                        <div className="bg-warning p-2">
                                                            <p>{data.StartTime}</p>
                                                            <p>{data.EndTime}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="event_card_body">
                                                    <h5>{data.Title}</h5>
                                                    <p className="para_add mt-3 mb-0" >
                                                        <i className="lni lni-user me-2"></i>{data.Organiser.substring(0, 30)} {data.Organiser.length > 30 ? "..." : ""}
                                                    </p>
                                                    <p className="para_add">
                                                        <i className="fadeIn animated bx bx-been-here"></i>{data.Venue.substring(0, 25)} {data.Venue.length > 25 ? "..." : ""}
                                                    </p>
                                                    <p className="para">
                                                        {data.About}
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            }) : null
                        }

                    </div>
                </div>
            </section>
            {/* event end here */}
        </>

    )

}

export default EventSection;