import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { BASE_URL } from "../../config";

const Client = () => {
    const [Clients, setClients] = useState([]);
    const user_token = secureLocalStorage.getItem("user_token");

    useEffect(() => {
        fetch(`${BASE_URL}/api/WebDashboard/GetClient`, {
            headers: {
                Authorization: `Bearer ${user_token}`
            }
        })
        .then(response => response.json())
        .then(result => {
            setClients(result);
        });
    }, []);

    return (
        <>
            {/* our client start */}
            <section style={{ padding: '70px 0' }}>
                <div className="container">
                    <div className="text-center">
                        <h2>Our Partners</h2>
                    </div>
                    <div className="client_top">
                        <Swiper
                            spaceBetween={20} // Decreased space between slides
                            slidesPerView={5}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {
                                Clients.map((data, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '10px',
                                            }}>
                                                <img 
                                                    src={data["Logo"]} 
                                                    alt={`Client logo ${index}`} 
                                                    style={{
                                                        borderRadius: '50%',
                                                        width: '90px',  // Adjusted size for better fit
                                                        height: '90px', // Adjusted size for better fit
                                                        objectFit: 'cover', // Ensures the image covers the area
                                                        boxShadow: '0 8px 8px rgba(0, 0, 0, 0.2)', // Optional: add some shadow for depth
                                                    }} 
                                                />
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                </div>
            </section>
            {/* our client end */}
        </>
    )
}

export default Client;
