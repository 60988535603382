import { Link } from "react-router-dom";

function ManageSubject() {
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">

                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Subject</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/"><i className="bx bx-home-alt"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Subject</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Mark</td>
                                                <td>
                                                    <button type="button" className="btn btn-outline-primary btn-sm mx-1 pt-0 px-1"><i className="lni lni-pencil me-0"></i></button>
                                                    <button type="button" className="btn btn-outline-danger btn-sm pt-0 px-1"><i className="lni lni-trash me-0"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Thornton</td>
                                                <td>
                                                    <button type="button" className="btn btn-outline-primary btn-sm mx-1 pt-0 px-1"><i className="lni lni-pencil me-0"></i></button>
                                                    <button type="button" className="btn btn-outline-danger btn-sm pt-0 px-1"><i className="lni lni-trash me-0"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageSubject;