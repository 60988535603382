import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { BASE_URL } from "../../config";



const Testimonial = () => {
    const [testimonial, setTestimonial] = useState([]);
    const user_token = secureLocalStorage.getItem("user_token");
    useEffect(() => {
        fetch(`${BASE_URL}/api/WebDashboard/GetStudentSay`, {
            headers: {
                Authorization: `Bearer ${user_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setTestimonial(result);
            })
        })
    }, [user_token]);
    return (
        <>
            {/* what our client say start  */}
            <section className="bg_pattern ptb_70">
                <div className="container">
                    <div className="text-center">
                        <h2>Student Say!</h2>
                    </div>
                    <div className="row">
                        {
                            testimonial.length ? testimonial.map((data, index) => {
                                return (
                                    <div key={index} className="col-md-4">
                                        <div className="test_card">
                                            <div className="img_sec_top">
                                                <img
                                                    src={data.Image}
                                                    className="img-fluid w-50"
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <h6>{data.Name}</h6>
                                                <span className="text-warning">{data.Designation}</span>
                                                <p className="mt-3">{data.Speak}</p>
                                            </div>
                                        </div>
                                    </div>  
                                )
                            }) : null
                        }

                    </div>
                </div>
            </section>
            {/* what our client say end  */}
        </>

    )

}

export default Testimonial;