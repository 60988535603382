import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { BASE_URL } from "../../config";

function AddCourse() {
    const { id } = useParams();
    const adm_token = secureLocalStorage.getItem("adm_token");
    const baseURL = `${BASE_URL}/api/`;
    const [Courses, setCourses] = useState([]);
    const [CourseCat, setCourseCat] = useState([]);
    const [FormValues, setFormValues] = useState({ IsActive: 1, Updatedby: "ADMIN" });

    function ChangeInputValues(e) {
        const { name, value } = e.target;
        setFormValues({
            ...FormValues,
            [name]: value
        });
    }


    function fetchCourses(id) {
        fetch(`${baseURL}Course/GetAllCourseByOId?OId=${id}`, {
            headers: {
                Authorization: `Bearer ${adm_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setCourses(result);
                console.log(result);
            });
        });
    }

    useEffect(() => {
        if (id) {
            fetchCourses(id);
        }
        fetch(`${baseURL}/Course/GetCourseCategoryByOId?OId=0`, {
            headers: {
                Authorization: `Bearer ${adm_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setCourseCat(result);
                document.getElementById("CourseCategory").value = "";
                document.getElementById("select_course").value = "";
            });
        });
    }, []);


    function handleCourseSubmit(e) {
        e.preventDefault();
        const CourseToast = toast.loading("Processing...");
        const formData = new URLSearchParams();
        for (const key in FormValues) {
            formData.append(key, FormValues[key]);
        }

        fetch(`${baseURL}/Course/PostInsertCourse`, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: `Bearer ${adm_token}`
            }
        }).then(response => {
            response.json().then(result => {
                console.log(result);
                if (result.Message === "Course Successfully Added ! Wait for Appoval") {
                    toast.update(CourseToast, { type: "success", autoClose: 5000, isLoading: false, render: result.Message });
                } else {
                    toast.update(CourseToast, { type: "error", autoClose: 5000, isLoading: false, render: "All fields are required" });
                }
            })
        })
    }

    function HandleFileChange(e) {
        const { name } = e.target;
        const value = "StudentImage/filename.png";
        const pass = {
            target: { name, value }
        };
        ChangeInputValues(pass);
    }

    // IsActive
    // Updatedby
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">

                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Course</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/"><i className="bx bx-home-alt"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Add Course</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <form onSubmit={handleCourseSubmit} className="row d-flex justify-content-center">
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body pb-0 row m-0">
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Title">Title</label>
                                            <input type="text" onChange={ChangeInputValues} className="form-control" placeholder="Title" id="Title" name="Title" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="ShortDescription">Short Description</label>
                                            <input type="text" onChange={ChangeInputValues} className="form-control" placeholder="Short Description" id="ShortDescription" name="ShortDescription" />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group my-2">
                                            <label htmlFor="FullDescription">Full Description</label>
                                            <textarea className="form-control" onChange={ChangeInputValues} placeholder="Short Description" id="FullDescription" name="FullDescription" ></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="CategoryId">Course Category</label>
                                            <select name="CategoryId" onChange={ChangeInputValues} className="form-control" id="CategoryId">
                                                <option value="">Select Category</option>
                                                {
                                                    CourseCat ? CourseCat.map((data, index) => {
                                                        return (
                                                            <option value={data["SID"]} key={index}>{data["StdName"]}</option>
                                                        )
                                                    }) : null
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="CategoryType">Category Type</label>
                                            <input type="text" onChange={ChangeInputValues} className="form-control" placeholder="Short Description" id="CategoryType" name="CategoryType" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Eligibility">Eligibility</label>
                                            <input type="text" onChange={ChangeInputValues} className="form-control" placeholder="Short Description" id="Eligibility" name="Eligibility" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Duration">Duration</label>
                                            <input type="text" onChange={ChangeInputValues} className="form-control" placeholder="Short Description" id="Duration" name="Duration" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="AttachFile">Attach File</label>
                                            <input type="file" onChange={HandleFileChange} className="form-control" placeholder="Short Description" id="AttachFile" name="AttachFile" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="AttachVideo">Attach Video</label>
                                            <input type="file" onChange={HandleFileChange} className="form-control" placeholder="Short Description" id="AttachVideo" name="AttachVideo" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="ProfileImage">Profile Image</label>
                                            <input type="file" onChange={HandleFileChange} className="form-control" placeholder="Short Description" id="ProfileImage" name="ProfileImage" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Rating">Rating</label>
                                            <input type="text" onChange={ChangeInputValues} className="form-control" placeholder="Short Description" id="Rating" name="Rating" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Message">Message</label>
                                            <input type="text" onChange={ChangeInputValues} className="form-control" placeholder="Short Description" id="Message" name="Message" />
                                        </div>
                                    </div>



                                    <p className="mt-4 text-end">
                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddCourse;