import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator } from "../STATES";
import SingUp from "./SingUp";
import { BASE_URL } from "../config";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const LoginState = useSelector(state => state.login);

    const { LoginAction } = bindActionCreators(actionCreator, dispatch);
    const [getFormValues, setGetFormValues] = useState({});

    function HandleInpChange(e) {
        const { name, value } = e.target;
        setGetFormValues({
            ...getFormValues,
            [name]: value
        });
    }


    async function handleSubmit(e) {
        e.preventDefault();
    
        toast.dismiss();
        const loginToast = toast.loading("Validating ...");
    
        const requestBody = {
            CompanyID: 'UMe',
            username: getFormValues["username"],
            password: getFormValues["password"]
        };
    
        try {
            // First fetch: Login attempt
            const loginResponse = await fetch(`${BASE_URL}/api/Login/Login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            const loginResult = await loginResponse.json();
    
            // Check if the login response has validation errors
            if (loginResult?.ModelState?.[""]?.[0]) {
                toast.update(loginToast, { type: "error", autoClose: 5000, isLoading: false, render: loginResult.ModelState[""][0] });
            } else if (loginResult["data"]) {
                const adm_token = loginResult["data"];
    
                // Second fetch: Role check using access token
                const roleResponse = await fetch(`${BASE_URL}/api/Account/GetCheckRole?Email=${getFormValues["username"]}`, {
                    headers: {
                        Authorization: `Bearer ${adm_token}`,
                    },
                });
    
                const roleResult = await roleResponse.json();
    
                if (roleResult.length) {
                    // Store necessary information in secureLocalStorage
                    secureLocalStorage.setItem("adm_token", adm_token);
                    secureLocalStorage.setItem("adm_name", roleResult[0]["Name"]);
                    secureLocalStorage.setItem("adm_username", roleResult[0]["Email"]);
                    secureLocalStorage.setItem("adm_password", getFormValues["password"]);
                    secureLocalStorage.setItem("adm_role", getFormValues["role"]);
    
                    // Handle successful login action
                    LoginAction(true);
                    toast.update(loginToast, { type: "success", autoClose: 5000, isLoading: false, render: "Logged in Successfully" });
    
                    // Navigate to admin page after a short delay
                    setTimeout(() => {
                        navigate("/admin/", { replace: true });
                    }, 100);
                } else {
                    // Handle case when the role is not found
                    toast.update(loginToast, { type: "error", autoClose: 5000, isLoading: false, render: "User Not Found !!" });
                }
            } else {
                // Handle generic error when the token is not returned
                toast.update(loginToast, { type: "error", autoClose: 5000, isLoading: false, render: "Something Went Wrong !!" });
            }
        } catch (error) {
            // Handle any network or unexpected errors
            console.error("An error occurred:", error);
            toast.update(loginToast, { type: "error", autoClose: 5000, isLoading: false, render: "Network or Server Error!" });
        }
    }
    

    return (
        <form onSubmit={handleSubmit}>

            <div className="form-group mt-3">
                <input
                    name="username"
                    type="text"
                    className="form-control"
                    placeholder="UserName"
                    onChange={HandleInpChange}
                />
            </div>
            <div className="form-group mt-3">
                <input
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={HandleInpChange}
                />
            </div>
            <div className="form-group mt-3">
                <select
                    name="role"
                    className="form-control" onChange={HandleInpChange}
                >
                    <option value={0}>
                        Select Role
                    </option>
                    <option value="1">Admin</option>
                    <option value="2">Staff</option>
                    <option value="3">Student</option>
                </select>
            </div>

            <button type="submit" className="btn btn-warning mt-3 w-100">Submit</button>


        </form>
    )
}

export default Login;