import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { BASE_URL } from "../../config";

const CourseSection = () => {
    const [course, setCourse] = useState([]);
    const user_token = secureLocalStorage.getItem("user_token");
    const navigate = useNavigate(); // For navigation

    useEffect(() => {
        fetch(`${BASE_URL}/api/Course/GetAllCourse`, {
            headers: {
                Authorization: `Bearer ${user_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setCourse(result);
            });
        });
    }, [user_token]);

    const star_arr = [1, 2, 3, 4, 5];

    // Handle 'View More' button click
    const handleViewMoreClick = () => {
        navigate("/course"); // Navigate to the full courses page
    };

    return (
        <>
            {/* our course start here */}
            <section className="ptb_70">
                <div className="container">
                    <div className="text-center">
                        <h2>Our Courses</h2>
                    </div>
                    <div className="row mt-4">
                        {
                            course.length ? course.slice(0, 4).map((data, index) => { // Only show first 4 courses
                                return (
                                    <div key={index} className="col-md-6">
                                        <a>
                                            <div className="card_course">
                                                <div className="row">
                                                    <div className="col-md-5 col-12 py-3 ps-4">
                                                        <div className="card_img_side">
                                                            <div className="img_abs">
                                                                <img src={data.ProfileImage} style={{ objectFit: "cover" }} className="img-fluid img_top" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 col-12 py-3 pe-4 ps-3">
                                                        <h5>{data.DivName}</h5>
                                                        <p className="mt-3">
                                                            {data.ShortDescription.substring(0, 73)} {data.ShortDescription.length > 73 ? "..." : ""}
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="me-5">
                                                                {
                                                                    star_arr.map((elm, ind) => {
                                                                        return parseInt(data.Rating) >= elm ?
                                                                            <i key={ind} className="lni lni-star-filled text-warning"></i> :
                                                                            <i key={ind} className="lni lni-star text-warning"></i>
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="d-flex">
                                                                <p className="me-3">
                                                                    <i className="lni lni-user text-warning"></i> {data.Users}
                                                                </p>
                                                                <p>
                                                                    <i className="lni lni-heart text-warning"></i> {data.Like}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="footer_card_course">
                                                            <div className="d-flex align-items-center">
                                                                <p className="m-0 ms-3 fw-bold">{data.Updatedby}</p>
                                                            </div>
                                                            <div>
                                                                <button className="btn btn-sm btn-outline-success">{data.Price}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                );
                            }) : null
                        }
                    </div>

                    {/* 'View More' button */}
                   
                    <div className="col-12 text-center mt-2 mb-5">
                        <button className="btn btn-warning py-3 px-5 text-white" onClick={handleViewMoreClick}>View More</button>
                    </div>
                </div>
            </section>
            {/* our course end here */}
        </>
    );
};

export default CourseSection;
