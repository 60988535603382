import { Link } from "react-router-dom";

function ManageAttendance() {
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">

                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Attendance</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/"><i className="bx bx-home-alt"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Attendance</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <form className="row d-flex justify-content-center">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body pb-0">
                                    <div className="form-group my-2">
                                        <label htmlFor="select_student">Select Student</label>
                                        <select className="form-control" id="select_student" name="subject_title" >
                                            <option value={0}>Select Student</option>
                                        </select>
                                    </div>
                                    <p className="mt-4 text-end">
                                        <button className="btn btn-primary" type="submit">Proceed</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ManageAttendance;