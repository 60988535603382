import React from "react";
import BlogSection from "./component/BlogSection";

const Blog = () => {
    return (
        <>
        <BlogSection />
        </>
    )
}

export default Blog;