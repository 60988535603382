import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { BASE_URL } from "../../config";

function ManageCourse() {
    const [Courses, setCourses] = useState([]);
    const adm_token = secureLocalStorage.getItem("adm_token");
    function fetchCourses() {
        fetch(`${BASE_URL}/api/Course/GetAllCourse`, {
            headers: {
                Authorization: `Bearer ${adm_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setCourses(result);
            });
        });
    }
    useEffect(() => {
        fetchCourses();
    }, []);

    function deleteCourse(course_id) {
        fetch(`${BASE_URL}/api/Course/PostDeleteCourseDivision?DID=${course_id}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            response.json().then(result => {
                console.log(result);
            });
        });
    }
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">

                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Course</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/"><i className="bx bx-home-alt"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Course</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-responsive mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Courses ? Courses.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{data.DivName}</td>
                                                            <td>₹{data.Price}</td>
                                                            <td>
                                                                <button type="button" onClick={() => { deleteCourse(data.DID) }} className="btn btn-outline-danger btn-sm pt-0 px-1"><i className="lni lni-trash me-0"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageCourse;