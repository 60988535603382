import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { BASE_URL } from "../config";

const Certificate = () => {
    const [getFormValues, setGetFormValues] = useState({});
    const [GetCertificate, setGetCertificate] = useState({ image: "", cls: "", fileExt: "" });
    const user_token = secureLocalStorage.getItem("user_token");
    console.log(user_token);
    
    function HandleInpChange(e) {
        const { name, value } = e.target;
        setGetFormValues({
            ...getFormValues,
            [name]: value
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        toast.dismiss();
        const FetchToast = toast.loading("Fetching ...");

        if (getFormValues.id && getFormValues.DOB) {
            fetch(`${BASE_URL}/api/Certificate/GetCertificateByIdDOB?id=${getFormValues.id}&DOB=${getFormValues.DOB}`, {
                headers: {
                    Authorization: `Bearer ${user_token}`
                },
                method: "GET"
            }).then(response => {
                return response.json();
            }).then(result => {
                if (result[0]?.SID) {
                    toast.update(FetchToast, { type: "success", autoClose: 5000, isLoading: false, render: "Loading Certificate.." });
                    setGetCertificate({ ...GetCertificate, cls: "remove_form" });
                    setTimeout(() => {
                        document.getElementById("certificateForm").style.display = "none";
                        const fileExt = result[0]["Image"].split(".").pop();
                        setGetCertificate({ ...GetCertificate, image: result[0]["Image"], fileExt });
                    }, 500);
                } else {
                    toast.update(FetchToast, { type: "error", autoClose: 5000, isLoading: false, render: "Certificate not found" });
                }
            });
        } else {
            toast.update(FetchToast, { type: "error", autoClose: 5000, isLoading: false, render: "Both Fields are mandatory" });
        }
    }

    function fillAgain() {
        document.getElementById("certificateForm").style.display = "block";
        setTimeout(() => {
            setGetCertificate({ cls: "", image: "" });
        }, 500);
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = GetCertificate.image;  // URL of the image
        link.download = `certificate.${GetCertificate.fileExt}`;  // Set the filename
        document.body.appendChild(link);  // Append the link to the body
        link.click();  // Simulate a click on the link
        document.body.removeChild(link);  // Remove the link after downloading
    };

    return (
        <>
            <section className="ptb_70">
                <div className="container">
                    <div className="row">
                        <div className={"col-md-4 mx-auto " + GetCertificate["cls"]} id="certificateForm">
                            <form className="card px-3 py-4" onSubmit={handleSubmit}>
                                <h5 className="text-center m-0">Please Enter Your Details</h5>
                                <hr />
                                <h6 className="mt-1">Username</h6>
                                <input name="id" type="text" onChange={HandleInpChange} className="form-control" placeholder="UserName" />
                                <h6 className="mt-3">Date of Birth</h6>
                                <input name="DOB" type="date" onChange={HandleInpChange} className="form-control" />
                                <button type="submit" className="btn btn-warning mt-4 text-white">Submit</button>
                            </form>
                        </div>
                        <div className="col-md-9 mx-auto"></div>
                        <div className="col-md-7 mx-auto">
                            {GetCertificate.image ? (
                                <>
                                    <iframe src={GetCertificate.image} id="iframe_window" className="img-fluid w-100" style={{ aspectRatio: "11/9" }} />
                                    <button className="btn btn-sm btn-primary mx-1" onClick={fillAgain}>Fill Form Again</button>
                                    <button className="btn btn-sm btn-warning mx-1" onClick={handleDownload}>Download</button>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Certificate;
