import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { BASE_URL } from "../config";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const Contact = () => {
    const [FormData, setFormData] = useState({});
    const user_token = secureLocalStorage.getItem("user_token");
    const [ContactDetail, setContactDetail] = useState([]);
    const [mapPosition, setMapPosition] = useState({ lat: 0, lng: 0 });

    function changeInputVal(e) {
        const { name, value } = e.target;
        setFormData({
            ...FormData,
            [name]: value
        });
    }

    useEffect(() => {
        fetch(`${BASE_URL}/api/WebDashboard/GetContactUs`, {
            headers: {
                Authorization: `Bearer ${user_token}`
            },
        }).then(response => response.json()).then(result => {
            setContactDetail(result);
            if (result[0]) {
                const fullAddress = `${result[0]["Address1"] || ""}, ${result[0]["Address2"] || ""}, ${result[0]["Address3"] || ""}`;
                geocodeAddress(fullAddress);
            }
        });
    }, []);

    // Geocode the address to get latitude and longitude
    const geocodeAddress = (address) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status) => {
            if (status === "OK" && results[0]) {
                const { lat, lng } = results[0].geometry.location;
                setMapPosition({ lat: lat(), lng: lng() });
            } else {
                console.error("Geocode was not successful for the following reason: " + status);
            }
        });
    };

    function submitMessage(e) {
        e.preventDefault();
        toast.dismiss();
        const submitForm = toast.loading("Processing!! Please Wait..");

        const requestData = {
            model: {
                Subject: FormData.Subject || "",
                Email: FormData.Email || "",
                Compose: FormData.Compose || "",
                SID: 0,
                OUTMSG: ""
            }
        };

        // Send the POST request
        fetch(`${BASE_URL}/api/WebDashboard/SendSuggestions`, {
            headers: {
                'Authorization': `Bearer ${user_token}`,
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(requestData)
        })
            .then(response => response.json())
            .then(result => {
                if (result.Item2 === "SUCCESS") {
                    toast.update(submitForm, { isLoading: false, autoClose: 5000, type: "success", render: result.Item2 });
                    document.getElementById("suuggestionForm").reset();
                } else {
                    toast.update(submitForm, { isLoading: false, autoClose: 5000, type: "error", render: result.Item2 });
                }
            })
            .catch(error => {
                toast.update(submitForm, { isLoading: false, autoClose: 5000, type: "error", render: "Something went wrong!" });
                console.error("Error:", error);
            });
    }

    return (
        <>
            <section className="ptb_70 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="left_side text-center">
                                {
                                    ContactDetail[0] ? (
                                        <>
                                            <div className="address">
                                                <i className="fa-solid fa-location-dot icon_contact"></i>
                                                <h5 className="mt-2">Address</h5>
                                                <p className="my-1">{ContactDetail[0]["Address1"] || ""}, {ContactDetail[0]["Address2"] || ""}</p>
                                                <p className="my-1">{ContactDetail[0]["Address3"] || ""}</p>
                                            </div>
                                            <div className="phone mt-4">
                                                <i className="fa-solid fa-phone icon_contact"></i>
                                                <h5 className="mt-2">Phone</h5>
                                                <p className="my-1">{ContactDetail[0]["Mobile"] || ""}</p>
                                                <p className="my-1">{ContactDetail[0]["Mobile1"] || ""}</p>
                                            </div>
                                            <div className="email mt-4">
                                                <i className="fa-solid fa-envelope icon_contact"></i>
                                                <h5 className="mt-2">Email</h5>
                                                <p className="my-1">{ContactDetail[0]["Email"] || ""}</p>
                                            </div>
                                        </>
                                    ) : null
                                }
                                {/* Display Google Map */}
                                <div className="mt-4">
                                    <LoadScript googleMapsApiKey="AIzaSyANi_I7GrDmJL17Qy_XVZTjySSthICuuYc">
                                        <GoogleMap
                                            mapContainerStyle={{ width: "100%", height: "400px" }}
                                            center={mapPosition}
                                            zoom={15}
                                        >
                                            <Marker position={mapPosition} />
                                        </GoogleMap>
                                    </LoadScript>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8 right_side">
                            <form id="suuggestionForm" onSubmit={submitMessage}>
                                <h3 style={{ color: "#3e2093", fontWeight: "600" }}>Send us a message</h3>
                                <p className="mt-3">If you have any work from me or any types of queries related to my tutorial, you can send me a message from here. It's my pleasure to help you.</p>
                                <input type="text" className="form-control mt-3" name="Subject" onChange={changeInputVal} placeholder="Enter Your Subject" />
                                <input type="email" className="form-control mt-3" name="Email" onChange={changeInputVal} placeholder="Enter Your Email" />
                                <textarea id="" cols="30" rows="5" className="w-100 mt-3 p" name="Compose" onChange={changeInputVal} placeholder="Enter Your Message"></textarea>
                                <button type="submit" className="btn btn-warning mt-4 w-25 text-white">Send Now</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;
