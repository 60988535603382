import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import "../assets/menu"
import { BASE_URL } from "../../config";

function Home() {
    const [DashboardData, setDashboardData] = useState([]);
    const role = secureLocalStorage.getItem("adm_role");
    console.log(role)
    const adm_token = secureLocalStorage.getItem("adm_token");
    function fetchDashBoardData() {

        fetch(`${BASE_URL}/api/Report/GetDashboardReport`, {
            headers: {
                Authorization: `Bearer ${adm_token}`,
            }
        }).then(response => {
            response.json().then(result => {
                setDashboardData(result);
            });
        });
    }

    useEffect(() => {
        fetchDashBoardData();
    }, []);
    return (
        <div className="page-wrapper">
            <div className="page-content">
                <div className="row">
                    {
                        role && role === '1' ? (
                            <>
                                <div className="col-md-3">
                                    <div className="card radius-10 overflow-hidden bg-gradient-kyoto">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <p className="mb-0 text-white">Certificate Issued</p>
                                                    <h5 className="mb-0 text-white">{DashboardData ? DashboardData[0] ? DashboardData[0]["Issued_Certificate"] : 0 : 0}</h5>
                                                </div>
                                                <div className="ms-auto text-white"><i className="bx bx-cart font-30"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card radius-10 overflow-hidden bg-white">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <p className="mb-0 text-primary">Active Students</p>
                                                    <h5 className="mb-0 text-primary">{DashboardData ? DashboardData[0] ? DashboardData[0]["Student_Active"] : 0 : 0}</h5>
                                                </div>
                                                <div className="ms-auto text-primary"><i className="bx bx-wallet font-30"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card radius-10 overflow-hidden bg-white">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <p className="mb-0 text-primary">Canceled Addmission</p>
                                                    <h5 className="mb-0 text-primary">{DashboardData ? DashboardData[0] ? DashboardData[0]["Student_CancelAdmission"] : 0 : 0}</h5>
                                                </div>
                                                <div className="ms-auto text-primary"><i className="bx bx-bulb font-30"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card radius-10 overflow-hidden bg-white">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <p className="mb-0 text-primary">Student Enrolled</p>
                                                    <h5 className="mb-0 text-primary">{DashboardData ? DashboardData[0] ? DashboardData[0]["Student_NewEnroll"] : 0 : 0}</h5>
                                                </div>
                                                <div className="ms-auto text-primary"><i className="bx bx-chat font-30"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card radius-10 overflow-hidden bg-white">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <p className="mb-0 text-primary">Student Passed</p>
                                                    <h5 className="mb-0 text-primary">{DashboardData ? DashboardData[0] ? DashboardData[0]["Student_Passed"] : 0 : 0}</h5>
                                                </div>
                                                <div className="ms-auto text-primary"><i className="bx bx-chat font-30"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="card radius-10 overflow-hidden bg-white">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <p className="mb-0 text-primary">Total Collection</p>
                                                    <h5 className="mb-0 text-primary">{DashboardData ? DashboardData[0] ? DashboardData[0]["Total_Collection"] : 0 : 0}</h5>
                                                </div>
                                                <div className="ms-auto text-primary"><i className="bx bx-chat font-30"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : role && role === '2' ? (
                            <div className="col">
                                <h4 className="">Hi {secureLocalStorage.getItem("adm_name") || "Guest User"}, </h4>
                                <h1 className="display-6">Welcome To Center Dashboard</h1>
                            </div>
                        ) : role && role === '3' ? (
                            <div className="col">

                                <h4 className="">Hi {secureLocalStorage.getItem("adm_name") || "Guest Student"},</h4>
                                <h1 className="display-6">Welcome To Center Dashboard</h1>
                            </div>
                        ) : null
                    }

                </div>
            </div>
        </div>
    )
}

export default Home;