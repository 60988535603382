import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { BASE_URL } from "../../config";

const Crousel = () => {
    const [Banner, setBanner] = useState([]);
    const user_token = secureLocalStorage.getItem("user_token");
    useEffect(() => {
        fetch(`${BASE_URL}/api/WebDashboard/GetSlider`, {
            headers: {
                Authorization: `Bearer ${user_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setBanner(result);
            })
        })
    }, [user_token]);

    return (
        <>
            {/* Crousal start here */}
            <div id="carouselExampleCaptions" className="carousel slide">

                <div className="carousel-inner">
                    {
                        Banner.length ? Banner.map((data, index) => {
                            let activeBanner = "";
                            if (index === 0) {
                                activeBanner = "active";
                            }
                            return (
                                <div key={index} className={`carousel-item ${activeBanner}`} style={{ position: "relative" }}>
                                    <div style={{ position: "absolute", zIndex: 1, width: "100%", height: "100%", top: 0, left: 0, background: "rgba(0,0,0,0.6)" }}></div>
                                    <img src={data.Image} className="d-block w-100 h-50" style={{ maxHeight: "90vh", objectFit: "cover", zIndex: 0, position: "relative" }} />
                                    <div className="carousel-caption d-none d-md-block" style={{ zIndex: 2, top: "48%", transform: "translateY(-50%)" }}>
                                        <h1 style={{ color: "#fff" }} className="fontRubik display-5">{data.Heading}</h1>
                                        <p className="mt-4 text-white">
                                            {data.Caption}
                                        </p>
                                        <div className="mt-5">
                                            <a href="#">
                                                <button className="btn btn-warning me-3">Get Started</button>
                                            </a>
                                            <a href="#">
                                                <button className="btn btn-light">Learn More</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : null
                    }

                </div>
                {
                    Banner.length ? (
                        <>
                            <button
                                className="carousel-control-prev"
                                type="button"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide="prev"
                            >
                                <span className="carousel-control-prev-icon" aria-hidden="true" />
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                                className="carousel-control-next"
                                type="button"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide="next"
                            >
                                <span className="carousel-control-next-icon" aria-hidden="true" />
                                <span className="visually-hidden">Next</span>
                            </button>
                        </>
                    ) : ""
                }
            </div>
            {/* crousal end here */}
        </>

    )
}

export default Crousel;