import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

function Sidebar() {
    const role = secureLocalStorage.getItem("adm_role");
    return (
        <div className="sidebar-wrapper" data-simplebar="true">
            <div className="sidebar-header">
                <div>
                    <img src="../assets/images/logo-icon.png" className="logo-icon" alt="logo icon" />
                </div>
                <div>
                    <h4 className="logo-text text-primary">IceCampus</h4>
                </div>
                <div className="toggle-icon ms-auto">
                    <i className='bx bx-first-page'></i>
                </div>
            </div>
            <ul className="metismenu" id="menu">
                <li>
                    <Link to="/admin/">
                        <div className="parent-icon">
                            <i className='bx bx-home'></i>
                        </div>
                        <div className="menu-title">Dashboard</div>
                    </Link>
                </li>
                {
                    role && role === '1' ? (
                        <>
                            <li>
                                <a href="#!" className="has-arrow" aria-expanded="false">
                                    <div className="parent-icon">
                                        <i className="bx bx-briefcase-alt-2"></i>
                                    </div>
                                    <div className="menu-title">Study Center</div>
                                </a>
                                <ul className="mm-collapse">
                                    <li>
                                        <Link to="/admin/manage-center"><i className="bx bx-right-arrow-alt"></i>Manage Center</Link>
                                        <Link to="/admin/add-center"><i className="bx bx-right-arrow-alt"></i>Add Center</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#!" className="has-arrow" aria-expanded="false">
                                    <div className="parent-icon">
                                        <i className="lni lni-graduation"></i>
                                    </div>
                                    <div className="menu-title">Students</div>
                                </a>
                                <ul className="mm-collapse">
                                    <li>
                                        <Link to="/admin/manage-student"><i className="bx bx-right-arrow-alt"></i>Manage Student</Link>
                                        <Link to="/admin/add-student"><i className="bx bx-right-arrow-alt"></i>Add Student</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#!" className="has-arrow" aria-expanded="false">
                                    <div className="parent-icon">
                                        <i className="lni lni-book"></i>
                                    </div>
                                    <div className="menu-title">Course</div>
                                </a>
                                <ul className="mm-collapse">
                                    <li>
                                        <Link to="/admin/manage-course"><i className="bx bx-right-arrow-alt"></i>Manage Course</Link>
                                        <Link to="/admin/add-course"><i className="bx bx-right-arrow-alt"></i>Add Course</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#!" className="has-arrow" aria-expanded="false">
                                    <div className="parent-icon">
                                        <i className="lni lni-users"></i>
                                    </div>
                                    <div className="menu-title">Staff</div>
                                </a>
                                <ul className="mm-collapse">
                                    <li>
                                        <Link to="/admin/manage-staff"><i className="bx bx-right-arrow-alt"></i>Manage Staff</Link>
                                        <Link to="/admin/add-staff"><i className="bx bx-right-arrow-alt"></i>Add Staff</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#!" className="has-arrow" aria-expanded="false">
                                    <div className="parent-icon">
                                        <i className="lni lni-infinite"></i>
                                    </div>
                                    <div className="menu-title">Session</div>
                                </a>
                                <ul className="mm-collapse">
                                    <li>
                                        <Link to="/admin/manage-session"><i className="bx bx-right-arrow-alt"></i>Manage Session</Link>
                                        <Link to="/admin/add-session"><i className="bx bx-right-arrow-alt"></i>Add Session</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/admin/attendance">
                                    <div className="parent-icon">
                                        <i className='lni lni-calendar'></i>
                                    </div>
                                    <div className="menu-title">Attendance</div>
                                </Link>
                            </li>

                        </>
                    ) : role === 2 ? (
                        <>
                            <li>
                                <a href="#!" className="has-arrow" aria-expanded="false">
                                    <div className="parent-icon">
                                        <i className="lni lni-graduation"></i>
                                    </div>
                                    <div className="menu-title">Students</div>
                                </a>
                                <ul className="mm-collapse">
                                    <li>
                                        <Link to="/admin/manage-student"><i className="bx bx-right-arrow-alt"></i>Manage Student</Link>
                                        <Link to="/admin/add-student"><i className="bx bx-right-arrow-alt"></i>Add Student</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#!" className="has-arrow" aria-expanded="false">
                                    <div className="parent-icon">
                                        <i className="lni lni-book"></i>
                                    </div>
                                    <div className="menu-title">Subject</div>
                                </a>
                                <ul className="mm-collapse">
                                    <li>
                                        <Link to="/admin/manage-subject"><i className="bx bx-right-arrow-alt"></i>Manage Subject</Link>
                                        <Link to="/admin/add-subject"><i className="bx bx-right-arrow-alt"></i>Add Subject</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/admin/manage-attendance">
                                    <div className="parent-icon">
                                        <i className='lni lni-calendar'></i>
                                    </div>
                                    <div className="menu-title">Manage Attendance</div>
                                </Link>
                            </li>

                        </>
                    ) : role === 3 ? (
                        <>
                            <li>
                                <Link to="/admin/profile">
                                    <div className="parent-icon">
                                        <i className='lni lni-user'></i>
                                    </div>
                                    <div className="menu-title">Profile</div>
                                </Link>
                            </li>
                        </>
                    ) : null
                }






            </ul>
        </div>
    )
}

export default Sidebar;