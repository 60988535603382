import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { BASE_URL } from "../../config";

function AddStaff() {
    const adm_token = secureLocalStorage.getItem("adm_token");
    const [Course, setCourse] = useState();
    const baseUrl = `${BASE_URL}/api/`;

    useEffect(() => {
        fetch(`${baseUrl}api/Course/GetCourseCategoryByOId?OId=0`, {
            headers: {
                Authorization: `Bearer ${adm_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setCourse(result);
            });
        });
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">

                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Staff</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/"><i className="bx bx-home-alt"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Add Staff</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <form className="row d-flex justify-content-center">
                        <div className="col-mb-8">
                            <div className="card">
                                <div className="card-body pb-0 row m-0">
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Name">Name</label>
                                            <input type="text" className="form-control" id="Name" placeholder="Name" name="Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="FatherName">Father Name</label>
                                            <input type="text" className="form-control" id="FatherName" placeholder="Father Name" name="FatherName" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="MotherName">Mother Name</label>
                                            <input type="text" className="form-control" id="MotherName" placeholder="Mother Name" name="MotherName" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="DateofBirth">Date of Birth</label>
                                            <input type="date" className="form-control" id="DateofBirth" name="DateofBirth" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="staff_phone">Course</label>
                                            <select className="form-control">
                                                <option value="">Select Course</option>
                                                {
                                                    Course ? Course.map((data, index) => {
                                                        return (
                                                            <option key={index} value={data["StdName"]} >{data["StdName"]}</option>
                                                        )
                                                    }) : null
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Email">Email</label>
                                            <input type="text" className="form-control" id="Email" placeholder="Email" name="Email" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Mobile">Mobile</label>
                                            <input type="text" className="form-control" id="Mobile" placeholder="Mobile" name="Mobile" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="AltMobile">Alt. Mobile</label>
                                            <input type="text" className="form-control" id="AltMobile" placeholder="Alt. Mobile" name="AltMobile" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Gender">Gender</label>
                                            <select className="form-control" id="Gender" name="Gender">
                                                <option value={"Male"}>Male</option>
                                                <option value={"Female"}>Female</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Specialty">Specialty</label>
                                            <input type="text" className="form-control" id="Specialty" placeholder="Specialty" name="Specialty" />
                                        </div>
                                    </div>

                                    
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Image">Image</label>
                                            <input type="file" className="form-control" id="Image" placeholder="Image" name="Image" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Qualification">Qualification</label>
                                            <input type="text" className="form-control" id="Qualification" placeholder="Qualification" name="Qualification" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="DateOfHiring">Date Of Hiring</label>
                                            <input type="text" className="form-control" id="DateOfHiring" placeholder="Date Of Hiring" name="DateOfHiring" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="YearsOfService">Years Of Service</label>
                                            <input type="text" className="form-control" id="YearsOfService" placeholder="Years Of Service" name="YearsOfService" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="TeachingExperience">Teaching Experience</label>
                                            <input type="text" className="form-control" id="TeachingExperience" placeholder="Teaching Experience" name="TeachingExperience" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="IndustrialExperience">Industrial Experience</label>
                                            <input type="text" className="form-control" id="IndustrialExperience" placeholder="Industrial Experience" name="IndustrialExperience" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Rating">Rating</label>
                                            <input type="number" className="form-control" id="Rating" placeholder="Rating" name="Rating" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="SocialLink">Social Link</label>
                                            <input type="text" className="form-control" id="SocialLink" placeholder="Social Link" name="SocialLink" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group my-2">
                                            <label htmlFor="Address">Address</label>
                                            <input type="text" className="form-control" id="Address" placeholder="Address" name="Address" />
                                        </div>
                                    </div>

                                    <div className="form-group my-2">
                                        <label htmlFor="staff_description">Description</label>
                                        <textarea className="form-control" id="staff_description" name="staff_description" ></textarea>
                                    </div>
                                    <p className="mt-4 text-end">
                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddStaff;