import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import RoleReducer from "./RoleReducer";


const reducers = combineReducers({
    login: LoginReducer,
    role: RoleReducer
});


export default reducers