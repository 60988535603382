import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { BASE_URL } from "../../config";

const HomeFooter = () => {
    const user_token = secureLocalStorage.getItem("user_token");
    const [ContactDetail, setContactDetail] = useState([]);
    const [FormData, setFormData] = useState({});
    function changeInputVal(e) {
        const { name, value } = e.target;
        setFormData({
            ...FormData,
            [name]: value
        });
    }
    useEffect(() => {
        fetch(`${BASE_URL}/api/WebDashboard/GetContactUs`, {
            headers: {
                Authorization: `Bearer ${user_token}`
            },
        }).then(response => response.json()).then(result => setContactDetail(result));
    }, []);

    function submitSubscribe(e) {
        e.preventDefault();
        toast.dismiss();
        const submitForm = toast.loading("Processing!! Please Wait..");
    
        // Create an object with the required structure
        const requestData = {
            model: {
                Email: FormData.Email || "",  
                Status: 1,                    
                OUTMSG: ""                    
            }
        };
    
        // Send the POST request
        fetch(`${BASE_URL}/api/WebDashboard/PostNewsSubscriptions`, {
            headers: {
                'Authorization': `Bearer ${user_token}`,
                'Content-Type': 'application/json'  
            },
            method: "POST",
            body: JSON.stringify(requestData)   
        })
        .then(response => response.json())  
        .then(result => {
             
            if (result.Item2 === "SUCCESS") {
                toast.update(submitForm, { isLoading: false, autoClose: 5000, type: "success", render: result.Item2 });
                document.getElementById("subsForm").reset();
            } else {
                toast.update(submitForm, { isLoading: false, autoClose: 5000, type: "error", render: result.Item2 });
            }
        })
        .catch(error => {
            // Handle network or other errors
            toast.update(submitForm, { isLoading: false, autoClose: 5000, type: "error", render: "Something went wrong!" });
            console.error("Error:", error);
        });
    }
    

    return (
        <>
            <>
                {/* footer start here */}
                <footer className="bg_blue_dark ptb_70 pb-0">
                    <div className="container">
                        {
                            ContactDetail[0] ? (
                                <div className="row">
                                    <div className="col-md-3">
                                        <div>
                                            <img src="./images/footer_logo.png" className="img-fluid" />
                                            <p className="mt-4">
                                                In conclusion, ICE is an excellent computer institute that
                                                provides quality education and training in the field of computer
                                                science. It is a great place for students who aspire to build a
                                                successful career in the IT industry and want to enhance their
                                                skills and knowledge in this field.
                                            </p>
                                            <ul>
                                                <li>
                                                    <i className="fa fa-map-marker-alt me-2" /> {ContactDetail[0]["Address1"]}, {ContactDetail[0]["Address2"]}, {ContactDetail[0]["Address3"]}
                                                </li>
                                                <li>
                                                    <a href={"mailto:" + ContactDetail[0]["Email"]}>
                                                        <i className="fa fa-envelope me-2" />
                                                        {ContactDetail[0]["Email"]}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={"tel:" + ContactDetail[0]["Mobile"]}>
                                                        <i className="fa fa-mobile-alt me-2" />{ContactDetail[0]["Mobile"]}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <h5 className="text-white mt-2">Useful Links</h5>
                                        <ul className="mt-4">
                                            <li>
                                                <Link to={"/"}>Join Us</Link>
                                            </li>
                                            <li>
                                                <Link to={"/about"}>About Us</Link>
                                            </li>
                                            <li>
                                                <Link to={"/"}>Features</Link>
                                            </li>
                                            <li>
                                                <Link to={"/contact"}>Feedback</Link>
                                            </li>
                                            <li>
                                                <Link to={"#"}>Support Center</Link>
                                            </li>
                                            <li>
                                                <Link to={"/contact"}>Contact Us</Link>
                                            </li>
                                            
                                        </ul>
                                    </div>

                                    <div className="col-md-3">
                                        <h5 className="text-white mt-2">Recent Posts</h5>
                                        <ul className="mt-4">
                                            <li>
                                                <div className="d-flex">
                                                    <div className="me-2 mt-2">
                                                        <a href="#">
                                                            <img
                                                                src="./images/letest_post1.jpg"
                                                                alt="letest_post1"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="post_content">
                                                        <h6>
                                                            <a href="#">Lorem ipsum dolor sit amet, consectetur</a>
                                                        </h6>
                                                        <span className="para">April 14, 2018</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex">
                                                    <div className="me-2 mt-2">
                                                        <a href="#">
                                                            <img
                                                                src="./images/letest_post2.jpg"
                                                                alt="letest_post1"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="post_content">
                                                        <h6>
                                                            <a href="#">Lorem ipsum dolor sit amet, consectetur</a>
                                                        </h6>
                                                        <span className="para">April 14, 2018</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex">
                                                    <div className="me-2 mt-2">
                                                        <a href="#">
                                                            <img
                                                                src="./images/letest_post3.jpg"
                                                                alt="letest_post1"
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="post_content">
                                                        <h6>
                                                            <a href="#">Lorem ipsum dolor sit amet, consectetur</a>
                                                        </h6>
                                                        <span className="para">April 14, 2018</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <h5 className="text-white mt-2">Subscribe Newsletter</h5>
                                        <p className="mt-4">
                                            We are excited to bring you the latest news and updates from ICE,
                                            your trusted source for all things technology. As a subscriber to
                                            our newsletter.
                                        </p>
                                        <div className="newsletter_form form_style2 mb-4">
                                            <form className="form_edit" onSubmit={submitSubscribe} id="subsForm">
                                                <input type="text" onChange={changeInputVal} name="Email" placeholder="Email Address" />
                                                <button title="Subscribe" type="submit" className="btn btn-warning">
                                                    Subscribe
                                                </button>
                                            </form>
                                        </div>
                                        <h5 className="text-white">Follow Us</h5>
                                        <ul className="d-flex">
                                            <li>
                                                <a href={ContactDetail[0]["Facebook"]}>
                                                    <i className="fa-brands fa-facebook-f lni lni-facebook"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={ContactDetail[0]["Twitter"]}>
                                                    <i className="fa-brands fa-twitter lni lni-twitter"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fa-brands fa-google-plus-g lni lni-google"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fa-brands fa-instagram lni lni-youtube"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fa-brands fa-instagram lni lni-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ) : null
                        }

                    </div>
                    <div className="container-fluid bg_foot_down">
                        <div className="row justify-content-between">
                            <div className="col-md-4">
                                <p>© 2018 All Rights Reserved by Ice Campus.</p>
                            </div>
                            <div className="col-md-4" style={{ marginBottom: "10px" }}>
                                {/*<a href="/policy">Privacy Policy</a>*/}
                                <Link to={"/policy"}>Privacy &amp; Policy &nbsp;&nbsp;&nbsp;</Link>
                                <Link to={"/privacy"}>Tearms &amp; Conditions</Link>
                                {/*<a href="/privacy" className="ms-5">*/}
                                {/*    Tearms &amp; Conditions*/}
                                {/*</a>*/}
                            </div>
                        </div>
                    </div>
                </footer>
                {/* footer end here */}

            </>

        </>
    )
}

export default HomeFooter;