import React from "react";
import AboutSection from "./component/AboutSection";


const About = () => {
    return (
        <>
        <AboutSection />
        </>
    )
}

export default About;