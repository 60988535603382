import React from "react";
import EventSection from "./component/EventSection";

const Event = () => {
    return (
        <>
        <EventSection />
        </>
    )
}

export default Event;