import React from "react";
import { NavLink } from "react-router-dom";
import Login from "../Login";


const Menu = () => {
    return (
        <>
            {/* menu bar start here */}
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container">
                    <NavLink to="/" className="navbar-brand text-white">
                        <img
                            src="./images/footer_logo.png"
                            alt="Ice Campus"
                            className="img-fluid"
                        />
                    </NavLink>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="lni lni-menu text-white"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink to="/" className="nav-link">Home</NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to="/course" className="nav-link" href="#">
                                    Courses
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to="/certificate" className="nav-link" href="#">
                                    Certificate
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to="/event" className="nav-link" href="#">
                                    Events
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to="/teacher" className="nav-link" href="#">
                                    Teacher
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to="/blog" className="nav-link" href="#">
                                    Blog
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to="/contact" className="nav-link" href="#">
                                    Contact
                                </NavLink>
                            </li>

                            <li className="dropdown">
                                <a className="dropdown-toggle nav-link" href="#" data-toggle="dropdown" aria-expanded="false">Notification</a>
                                <div className="dropdown-menu bg_blue_dark">
                                    <ul>
                                        <li><NavLink className="dropdown-item nav-link nav_item" href="CertificateVarification.aspx">News</NavLink></li>
                                        <li><NavLink className="dropdown-item nav-link nav_item" href="event-detail.html">Notice</NavLink></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/about" className="nav-link" href="#">
                                    About Us
                                </NavLink>
                            </li>

                        </ul>
                        <div className="d-flex">
                            <button className="btn" type="submit">
                                <i className="lni lni-search-alt text-white" />
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
            {/* menu bar end here */}
            <div className="modal">
                <Login />
            </div>
        </>
    )
}

export default Menu;