import React from "react";

const Video = () => {
    return (
        <>
            {/* video tutorial start here */}
            <section className="bg_video">
                <div
                    className="container-fluid"
                    style={{ backgroundColor: "rgba(0,0,0,0.5)", height: 300 }}
                >
                    <div className="row">
                        <div className="bg_video_in">
                            <i className="lni lni-play" />
                            <h3 className="mt-5 text-white">Video Tutorial For Our Campus</h3>
                        </div>
                    </div>
                </div>
            </section>
            {/* video tutorial end here */}
        </>

    )
}

export default Video;