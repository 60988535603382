import { Link } from "react-router-dom";

function AddSession() {
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">

                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Session</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/"><i className="bx bx-home-alt"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Add Session</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <form className="row d-flex justify-content-center">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body pb-0">
                                    <div className="form-group my-2">
                                        <label htmlFor="session_title">Title</label>
                                        <input type="text" className="form-control" id="session_title" placeholder="Name" name="session_title" />
                                    </div>
                                    <div className="form-group my-2">
                                        <label htmlFor="session_date">Date</label>
                                        <input type="date" className="form-control" id="session_date" name="session_date" />
                                    </div>
                                    <div className="form-group my-2">
                                        <label htmlFor="session_description">Description</label>
                                        <textarea className="form-control" id="session_description" name="session_description" ></textarea>
                                    </div>
                                    <p className="mt-4 text-end">
                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddSession;