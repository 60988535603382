import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { BASE_URL } from "../config";

const SingUp = () => {
    const navigate = useNavigate();
    const [getFormValues, setGetFormValues] = useState({});
    const [passWordValidate, setpassWordValidate] = useState({ capital: false, len: false, specialChar: false, digit: false, small: false });
    const user_token = secureLocalStorage.getItem("user_token");
    function HandleInpChange(e) {
        const { name, value } = e.target;
        setGetFormValues({
            ...getFormValues,
            [name]: value
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        fetch(`${BASE_URL}/api/Account/GetCheckRole?Email=${getFormValues["Email"]}`, {
            headers: {
                Authorization: `Bearer ${user_token}`,
            }
        }).then(response => {
            return response.json();
        }).then(result => {
            if (result.length) {
                const formData = new URLSearchParams();
                formData.append("Role", getFormValues["Role"]);
                formData.append("Email", getFormValues["Email"]);
                formData.append("Password", getFormValues["Password"]);
                formData.append("ConfirmPassword", getFormValues["ConfirmPassword"]);
                if (getFormValues["Password"] === getFormValues["ConfirmPassword"]) {
                    fetch(`${BASE_URL}/api/Account/Register`, {
                        method: "POST",
                        body: formData,
                        headers: {
                            Authentication: `Bearer ${user_token}`
                        }
                    }).then(response => {
                        response.json().then(result => {
                            if (result === true) {
                                navigate("/login", { replace: true });
                            }
                        });
                    });
                } else {
                    toast.error("Both Password should be same");
                }
            } else {
                toast.error("User Not Found");
            }
        });
    }

    function password_validate(password) {
        var re = {
            capital: /(?=.*[A-Z])/,
            small: /(?=.*[a-a])/,
            length: /(?=.{8,40}$)/,
            specialChar: /[ -\/:-@\[-\`{-~]/,
            digit: /(?=.*[0-9])/,
        };
        const response = {};
        response.capital = re.capital.test(password) ? true : false;
        response.len = re.length.test(password) ? true : false;
        response.specialChar = re.specialChar.test(password) ? true : false;
        response.digit = re.digit.test(password) ? true : false;
        response.small = re.small.test(password) ? true : false;
        setpassWordValidate(response);
    }


    function passwordValidator(e) {
        console.log(password_validate(e.target.value));
    }

    return (
        <form onSubmit={handleSubmit} className="mx-auto bg-white py-2 px-3 rounded">
            <div className="form-group mt-3">
                <select
                    name="Role"
                    id="ContentPlaceHolder1_drpRole"
                    className="form-control"
                    onChange={HandleInpChange}
                >
                    <option value="">Select Role</option>
                    <option value="3">Student</option>
                    <option value="2">Staff</option>
                    <option value="1">Admin</option>
                </select>
            </div>

            <div className="form-group mt-3">
                <input
                    name="Email"
                    type="text"
                    id="ContentPlaceHolder1_txtstuuname"
                    className="form-control"
                    placeholder="UserName"
                    required=""
                    onChange={HandleInpChange}
                />
            </div>
            <div className="form-group mt-3">
                <input
                    name="Password"
                    type="password"
                    id="ContentPlaceHolder1_txtstupass"
                    className="form-control"
                    placeholder="Password"
                    onChange={e => { HandleInpChange(e); passwordValidator(e) }}
                />
            </div>

            <div className="form-group mt-3">
                <input
                    name="ConfirmPassword"
                    type="password"
                    id="ContentPlaceHolder1_txtstupass"
                    className="form-control"
                    placeholder="Confirm Password"
                    onChange={HandleInpChange}
                />
            </div>

            <br />
            <span>
                {
                    !passWordValidate.capital ? (<i className="lni lni-close text-danger" style={{ fontWeight: 900 }}></i>) : (<i style={{ fontWeight: 900 }} className="lni lni-checkmark text-success"></i>)
                }
                <span className="ms-1"> Upper Case <br /></span>
                {
                    !passWordValidate.small ? (<i className="lni lni-close text-danger" style={{ fontWeight: 900 }}></i>) : (<i style={{ fontWeight: 900 }} className="lni lni-checkmark text-success"></i>)
                }
                <span className="ms-1"> Lower Case <br /></span>
                {
                    !passWordValidate.digit ? (<i className="lni lni-close text-danger" style={{ fontWeight: 900 }}></i>) : (<i style={{ fontWeight: 900 }} className="lni lni-checkmark text-success"></i>)
                }
                <span className="ms-1"> Numeric <br /></span>
                {
                    !passWordValidate.specialChar ? (<i className="lni lni-close text-danger" style={{ fontWeight: 900 }}></i>) : (<i style={{ fontWeight: 900 }} className="lni lni-checkmark text-success"></i>)
                }
                <span className="ms-1"> Special Char. <br /></span>
                {
                    !passWordValidate.len ? (<i className="lni lni-close text-danger" style={{ fontWeight: 900 }}></i>) : (<i style={{ fontWeight: 900 }} className="lni lni-checkmark text-success"></i>)
                }
                <span className="ms-1"> Length </span>
            </span>

            <div className="form-group mt-4">
                <input
                    type="submit"
                    name="ctl00$ContentPlaceHolder1$btnstudenSingUp"
                    defaultValue="SingUp"
                    id="ContentPlaceHolder1_btnstudenSingUp"
                    className="btn btn-default btn-warning text-white w-100 rounded-0"
                />
            </div>
        </form>
    )
}

export default SingUp;