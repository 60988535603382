import React from "react";
import { useState } from "react";

const Why = () => {
    const [expanded, setExpanded] = useState(Array(6).fill(false)); 
  
    const toggleReadMore = (index) => {
      const newExpanded = [...expanded];
      newExpanded[index] = !newExpanded[index];
      setExpanded(newExpanded);
    };
  
    const cardData = [
      {
        title: "Scholarship",
        icon: "lni lni-graduation",
        content:
          "Experienced Faculty: At ICE, we take pride in our highly skilled and experienced faculty members who are experts in their respective fields. Our instructors have extensive industry knowledge and are passionate about imparting their expertise to our students. You can be confident that you'll receive top-quality education from professionals who are dedicated to your success.",
      },
      {
        title: "Books & Library",
        icon: "lni lni-library",
        content:
          "Comprehensive Course Offerings: ICE offers a wide range of courses to cater to different interests and skill levels. Whether you're a beginner looking to learn the basics or an experienced professional seeking advanced training, we have courses that fit your needs. From programming languages to web development, database management to cybersecurity, our curriculum covers the latest industry trends and technologies.",
      },
      {
        title: "Learn Courses Online",
        icon: "lni lni-world",
        content:
          "Practical Hands-on Training: We believe in the importance of practical, hands-on training to enhance your learning experience. Our courses emphasize real-world projects, case studies, and interactive sessions to ensure you gain practical skills that can be applied directly to your future career. You'll have access to our state-of-the-art computer labs equipped with the latest software and hardware, allowing you to get hands-on experience in a supportive environment.",
      },
      {
        title: "Expert Instructors",
        icon: "lni lni-user",
        content:
          "Industry-Relevant Curriculum: Our curriculum is regularly updated to align with industry standards and emerging technologies. We collaborate with industry professionals and organizations to ensure our courses are up to date and reflect the current demands of the job market. By studying with us, you'll acquire the knowledge and skills that are in high demand, increasing your employability and career prospects.",
      },
      {
        title: "Kids Education",
        icon: "lni lni-school-bench-alt",
        content:
          "Job Placement Assistance: We are committed to your success beyond the classroom. ICE provides job placement assistance to help you kickstart your career in the IT industry. Our dedicated career services team offers resume building, interview preparation, and networking opportunities to connect you with potential employers. We strive to empower our students to secure fulfilling job positions upon completion of their courses.",
      },
      {
        title: "Music Programs",
        icon: "lni lni-music",
        content:
          "Flexible Learning Options: We understand that our students have diverse schedules and commitments. That's why we offer flexible learning options to accommodate your needs. You can choose from full-time or part-time courses, and we also offer online learning for those who prefer a more flexible study environment. We believe that education should be accessible to all, and we strive to make it convenient for you to pursue your passion for computer education.",
      },
    ];
  
    return (
      <section className="ptb_70">
        <div className="container">
          <div className="text-center">
            <h3>Why Choose Us</h3>
            <p className="mt-3">
              If you are going to use a passage of you need to be sure there isn't
              anything <br />
              embarrassing hidden in the middle of text
            </p>
          </div>
          <div className="row justify-content-center">
            {cardData.map((card, index) => (
              <div key={index} className="col-md-4">
                <div className="card_choose">
                  <div className="row">
                    <div className="col-md-12 d-flex">
                      <i className={card.icon} />
                      <h4 className="ms-2">{card.title}</h4>
                    </div>
                    <div className="col-md-12 mt-2">
                      <p>
                        {expanded[index]
                          ? card.content // Show full content if expanded
                          : card.content.slice(0, 100) + "..."} {/* Show truncated content */}
                      </p>
                      <button
                        className="btn btn-link p-0"
                        onClick={() => toggleReadMore(index)}
                      >
                        {expanded[index] ? "Read Less" : "Read More"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
export default Why;