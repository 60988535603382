function Footer (){
    return(
        <>
        <div className="search-overlay"></div>
		<div className="overlay toggle-icon"></div>
        <a href="#!" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
		<footer className="page-footer">
			<p className="mb-0">Copyright © 2021. All right reserved.</p>
		</footer>
        </>
    )
}

export default Footer;