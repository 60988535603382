import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { BASE_URL } from "../../config";

function AddCenter() {
    const params = useParams();
    const adm_token = secureLocalStorage.getItem("adm_token");
    const baseUrl = `${BASE_URL}/api`;
    const [formValues, setFormValues] = useState([]);
    const [AllCountry, setAllCountry] = useState([]);
    const [State, setState] = useState([]);
    const [City, setCity] = useState([]);



    function changeInputVal(e) {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });

        console.log(formValues);
    }

    function SetCustomVal(event) {
        const key = event.target.options[event.target.selectedIndex].getAttribute('data-key');
        const val = event.target.options[event.target.selectedIndex].getAttribute('data-val');
        setFormValues({
            ...formValues,
            [key]: val
        });
    }



    useEffect(() => {
        fetch(`${baseUrl}/Others/GetCountryAll`, {
            headers: {
                Authorization: `Bearer ${adm_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setAllCountry(result);
            });
        });

        if (params.id) {
            fetch(`${baseUrl}/StudyCenter/GetRegisteredStudyCenter?OId=${params.id}`, {
                headers: {
                    Authorization: `Bearer ${adm_token}`
                }
            }).then(response => {
                response.json().then(result => {
                    setFormValues(result[0]);
                });
            });
        }
    }, [adm_token]);


    function changeCountry(countryId) {
        document.getElementById("select_state").value = "";
        document.getElementById("select_City").value = "";
        if (countryId) {
            fetch(`${baseUrl}/Others/GetStateByCountryId?countryID=${countryId}`, {
                headers: {
                    Authorization: `Bearer ${adm_token}`
                }
            }).then(response => {
                response.json().then(result => {
                    setState(result);
                });
            })
        }
    }

    function changeState(stateId) {
        document.getElementById("select_City").value = "";
        if (stateId) {
            fetch(`${baseUrl}/Others/GetCityByStateId?stateID=${stateId}`, {
                headers: {
                    Authorization: `Bearer ${adm_token}`
                }
            }).then(response => {
                response.json().then(result => {
                    setCity(result);
                });
            })
        }
    }


    function HandleFileChange(e) {
        const { name } = e.target;
        const value = "filename.png";
        const pass = {
            target: { name, value }
        };
        changeInputVal(pass);
    }


    function submitAddCenterForm(e) {
        e.preventDefault();
        const submitCenterForm = toast.loading("Processing!! Please Wait..");
        const formData = new URLSearchParams();
        for (const key in formValues) {
            formData.append(key, formValues[key]);
        }

        if (params.id) {
            fetch(`${baseUrl}/StudyCenter/PostUpdateStudyCenter`, {
                headers: {
                    Authorization: `Bearer ${adm_token}`
                },
                method: "POST",
                body: formData
            }).then(response => {
                response.json().then(result => {
                    if (result && result.Message === "Update Successfully ! ") {
                        toast.update(submitCenterForm, { type: "success", autoClose: 5000, isLoading: false, render: result.Message });
                    } else {
                        toast.update(submitCenterForm, { type: "error", autoClose: 5000, isLoading: false, render: "Every Field Is Required" })
                    }
                });
            });
        } else {
            fetch(`${baseUrl}/StudyCenter/PostRegisterStudyCenter`, {
                headers: {
                    Authorization: `Bearer ${adm_token}`
                },
                method: "POST",
                body: formData
            }).then(response => {
                response.json().then(result => {
                    if (result && result.Message === "Register Successfully ! ") {
                        toast.update(submitCenterForm, { type: "success", autoClose: 5000, isLoading: false, render: result.Message });
                        setFormValues({});
                    } else {
                        toast.update(submitCenterForm, { type: "error", autoClose: 5000, isLoading: false, render: "Every Field Is Required" })
                    }
                });
            });
        }
    }
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">

                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Study Center</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/"><i className="bx bx-home-alt"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Add Center</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <form className="row d-flex justify-content-center" onSubmit={submitAddCenterForm}>
                        <div className="col-md-11">
                            <div className="card">
                                <div className="card-body pb-0 row mx-0">
                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="KRCCode">KRC Code</label>
                                            <input type="number" className="form-control" value={formValues && formValues["KRCCode"] ? formValues["KRCCode"] : undefined} placeholder="KRCCode" id="KRCCode" name="KRCCode" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="Branch">Branch</label>
                                            <input type="number" className="form-control" onChange={changeInputVal} value={formValues && formValues["Branch"] ? formValues["Branch"] : undefined} placeholder="Branch" id="Branch" name="Branch" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="KRCName">KRC Name</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["KRCName"] ? formValues["KRCName"] : undefined} placeholder="KRCName" id="KRCName" name="KRCName" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="Image">Image</label>
                                            <input type="file" className="form-control" onChange={HandleFileChange} placeholder="Image" id="Image" name="Image" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="Logo">Logo</label>
                                            <input type="file" className="form-control" onChange={HandleFileChange} placeholder="Logo" id="Logo" name="Logo" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="KRCType">KRC Type</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["KRCType"] ? formValues["KRCType"] : undefined} placeholder="KRCType" id="KRCType" name="KRCType" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="KRCFame">KRC Fame</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["KRCFame"] ? formValues["KRCFame"] : undefined} placeholder="KRCFame" id="KRCFame" name="KRCFame" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="Authorized">Authorized</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["Authorized"] ? formValues["Authorized"] : undefined} placeholder="Authorized" id="Authorized" name="Authorized" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="RegNo">RegNo</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["RegNo"] ? formValues["RegNo"] : undefined} placeholder="RegNo" id="RegNo" name="RegNo" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="Affliations">Affiliations</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["Affliations"] ? formValues["Affliations"] : undefined} placeholder="Affliations" id="Affliations" name="Affliations" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="HOD">HOD</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["HOD"] ? formValues["HOD"] : undefined} placeholder="HOD" id="HOD" name="HOD" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="Address">Address</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["Address"] ? formValues["Address"] : undefined} placeholder="Address" id="Address" name="Address" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="select_country">Country</label>
                                            <select className="form-control" onChange={(e) => { changeCountry(e.target.value); }} onBlur={e => changeInputVal(e)} id="select_country" name="Country">
                                                <option value="" >Select Country</option>
                                                {
                                                    AllCountry.length ? AllCountry.map((data, index) => {
                                                        return (
                                                            <option value={data["countryID"]} data-key="Country" data-val={data["countryName"]} key={index}>{data["countryName"]}</option>
                                                        )
                                                    }) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="select_state">State</label>
                                            <select className="form-control" onChange={(e) => { changeState(e.target.value); }} onBlur={e => changeInputVal(e)} id="select_state" name="State">
                                                <option value="" >Select State</option>
                                                {
                                                    State.length ? State.map((data, index) => {
                                                        return (
                                                            <option value={data["stateID"]} data-key="State" data-val={data["stateName"]} key={index}>{data["stateName"]}</option>
                                                        )
                                                    }) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="select_City">City</label>
                                            <select className="form-control" onChange={changeInputVal} id="select_City" name="City">
                                                <option value="" >Select City</option>
                                                {
                                                    City.length ? City.map((data, index) => {
                                                        return (
                                                            <option value={data["cityName"]} key={index}>{data["cityName"]}</option>
                                                        )
                                                    }) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="Email">Email</label>
                                            <input type="email" className="form-control" onChange={changeInputVal} value={formValues && formValues["Email"] ? formValues["Email"] : undefined} placeholder="Email" id="Email" name="Email" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="Mobile">Mobile</label>
                                            <input type="number" className="form-control" onChange={changeInputVal} value={formValues && formValues["Mobile"] ? formValues["Mobile"] : undefined} placeholder="Mobile" id="Mobile" name="Mobile" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="About">About</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["About"] ? formValues["About"] : undefined} placeholder="About" id="About" name="About" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="WebSite">WebSite</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["WebSite"] ? formValues["WebSite"] : undefined} placeholder="WebSite" id="WebSite" name="WebSite" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="Rating">Rating</label>
                                            <input type="number" className="form-control" onChange={changeInputVal} value={formValues && formValues["Rating"] ? formValues["Rating"] : undefined} placeholder="Rating" id="Rating" name="Rating" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="SocialLink">SocialLink</label>
                                            <input type="url" className="form-control" onChange={changeInputVal} value={formValues && formValues["SocialLink"] ? formValues["SocialLink"] : undefined} placeholder="SocialLink" id="SocialLink" name="SocialLink" />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group my-2">
                                            <label htmlFor="Note">Note</label>
                                            <textarea type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["Note"] ? formValues["Note"] : undefined} placeholder="Note" id="Note" name="Note" ></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="NoteTitle">NoteTitle</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["NoteTitle"] ? formValues["NoteTitle"] : undefined} placeholder="NoteTitle" id="NoteTitle" name="NoteTitle" />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="Message">Message</label>
                                            <input type="text" className="form-control" onChange={changeInputVal} value={formValues && formValues["Message"] ? formValues["Message"] : undefined} placeholder="Message" id="Message" name="Message" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group my-2">
                                            <label htmlFor="Color">Color</label>
                                            <input type="color" className="form-control" onChange={changeInputVal} value={formValues && formValues["Color"] ? formValues["Color"] : undefined} placeholder="Color" id="Color" name="Color" />
                                        </div>
                                    </div>

                                    <p className="mt-4 text-end">
                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddCenter;