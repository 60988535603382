import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { BASE_URL } from "../../config";

const Teacher = () => {
    const [techer, setTecher] = useState([]);
    const user_token = secureLocalStorage.getItem("user_token");
    useEffect(() => {
        fetch(`${BASE_URL}/api/WebDashboard/GetTecherSay`, {
            headers: {
                Authorization: `Bearer ${user_token}`
            }
        }).then(response => {
            response.json().then(result => {
                setTecher(result);
            })
        })
    }, [user_token]);
    return (
        <>
            {/* our teachers start here */}
            <section className="ptb_70 bg_gray">
                <div className="container">
                    <div className="text-center">
                        <h2>Our Teachers</h2>
                    </div>
                    <div className="row mt-5 justify-content-center">
                        {techer.length
                            ? techer.map((data, index) => {
                                  return (
                                      <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                                          <a href="#">
                                              <div className="teacher_card">
                                                  <div className="teacher_card_head">
                                                      <div className="teacher_card_head_abs">
                                                          <img
                                                              src={data.Image}
                                                              alt="team"
                                                              className="img-fluid"
                                                          />
                                                      </div>
                                                  </div>
                                                  <div className="teacher_card_body">
                                                      <h5>{data.Name}</h5>
                                                      <p>{data.Designation}</p>
                                                      <div className="d-flex justify-content-center mt-3">
                                                          <i className="fa-brands fa-facebook-f lni lni-facebook" />
                                                          <i className="fa-brands fa-twitter lni lni-twitter" />
                                                          <i className="fa-brands fa-google-plus-g lni lni-google" />
                                                          <i className="fa-brands fa-instagram lni lni-instagram" />
                                                      </div>
                                                  </div>
                                              </div>
                                          </a>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            </section>
            {/* our teachers end here */}
        </>
    );
    
}

export default Teacher;