import { useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { BASE_URL } from "../../config";

function ForgotPassword() {
    const [Email, setEmail] = useState("");
    const user_token = secureLocalStorage.getItem("user_token");
    const navigate = useNavigate();
    const handleSubmit = e => {
        e.preventDefault();
        const FrgToast = toast.loading("Waiting For response !!");
        const target = `${BASE_URL}/api/Users/PasswordRecovery?email=${Email}`;
        fetch(target, {
            headers: {
                Authorization: `Bearer ${user_token}`
            },
            method: "POST"
        }).then(response => {
            return response.json();
        }).then(result => {
            toast.update(FrgToast, { isLoading: false, type: "success", autoClose: 5000, render: "New Password sent to mail!!" })
            document.getElementById("openLoginPopUp").click();
        });
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group mt-3">
                <input
                    name="username"
                    type="text"
                    className="form-control"
                    placeholder="UserName"
                    onChange={e => setEmail(e.target.value)}
                />
            </div>
            <button type="submit" className="btn btn-warning mt-3 w-100">Submit</button>
        </form>
    )
}

export default ForgotPassword;