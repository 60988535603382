import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { BASE_URL } from "../../config";

const Counter = () => {
    const [InfoList, setInfoList] = useState([{ "Total_Students": 0, Total_Course: 0, Total_Staff: 0, Total_Organization: 0 }]);
    const user_token = secureLocalStorage.getItem("user_token");
    useEffect(() => {
        fetch(`${BASE_URL}/api/WebDashboard/GetDashInfoList`, {
            headers: {
                Authorization: `Bearer ${user_token}`
            }
        }).then(response => {
            return response.json();
        }).then(result => {
            setInfoList(result[0]);
        });
    }, []);
    return (
        <>
            {/* counter start here */}
            <section className="ptb_70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-6 ">
                            <div className="text-center">
                                <div className="counter_icon">
                                    <img
                                        src="./images/counter_icon_dark1.png"
                                        alt="counter_icon1"
                                    />
                                </div>
                                <div className="counter_content mt-3">
                                    <h3 className="counter_text">
                                        <span className="counter">{InfoList["Total_Students"]}</span>
                                    </h3>
                                    <p>Students</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6 ">
                            <div className="text-center">
                                <div className="counter_icon">
                                    <img
                                        src="./images/counter_icon_dark2.png"
                                        alt="counter_icon2"
                                    />
                                </div>
                                <div className="counter_content mt-3">
                                    <h3 className="counter_text">
                                        <span className="counter">{InfoList["Total_Course"]}</span>
                                    </h3>
                                    <p>Courses</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6 ">
                            <div className="text-center">
                                <div className="counter_icon">
                                    <img
                                        src="./images/counter_icon_dark3.png"
                                        alt="counter_icon3"
                                    />
                                </div>
                                <div className="counter_content mt-3">
                                    <h3 className="counter_text">
                                        <span className="counter ng-binding">{InfoList["Total_Staff"]}</span>
                                    </h3>
                                    <p>Certified teachers</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6 ">
                            <div className="text-center">
                                <div className="counter_icon">
                                    <img
                                        src="/assets/images/building.png"
                                        alt="counter_icon4"
                                    />
                                </div>
                                <div className="counter_content mt-3">
                                    <h3 className="counter_text">
                                        <span className="counter">{InfoList["Total_Organization"]}</span>
                                    </h3>
                                    <p>Organizations</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* counter end here */}
        </>

    )

}

export default Counter;