import { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../config";

function chunk(arr = [], size) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
        const chunk = arr.slice(i, i + size);
        chunks.push(chunk);
    }
    return { chunks, len: chunks.length };
}

function ManageStudents() {
    const baseUrl = `${BASE_URL}/`;
    const [allStudents, setAllStudents] = useState([]);
    const [tableLen, settableLen] = useState([]);
    const [ShowIndex, setShowIndex] = useState(0);
    const adm_token = secureLocalStorage.getItem("adm_token");
    useEffect(() => {
        fetch(`${baseUrl}api/Report/GetStudentReport`, {
            headers: {
                Authorization: `Bearer ${adm_token}`
            }
        }).then(response => {
            response.json().then(result => {
                const { chunks, len } = chunk(result, 25);
                console.log(len);
                settableLen(len);
                setAllStudents(chunks);
            });
        })
    }, []);

    function deleteStudent(SID) {
        fetch(`${baseUrl}api/Student/DeleteStudent?SID=${SID}`, {
            headers: {
                Authorization: `Bearer ${adm_token}`
            },
            method: "DELETE"
        });
    }
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">

                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Students</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/"><i className="bx bx-home-alt"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Students</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allStudents.length ? allStudents[ShowIndex].map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row">{25 * ShowIndex + (index + 1)}</th>
                                                            <td>{data["Name"]}</td>
                                                            <td>{data["Email"]}</td>
                                                            <td>{data["Mobile"]}</td>
                                                            <td>
                                                                <Link to={"/admin/edit-student/" + data["SID"]} type="button" className="btn btn-outline-primary btn-sm mx-1 pt-0 px-1"><i className="lni lni-pencil me-0"></i></Link>
                                                                <button type="button" onClick={() => deleteStudent(data["SID"])} className="btn btn-outline-danger btn-sm pt-0 px-1"><i className="lni lni-trash me-0"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : (
                                                    <tr>
                                                        <td colSpan={5} className="text-center pt-4">
                                                            <i className="lni lni-spinner bx-spin fs-2"></i>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                    </table>
                                    <p className="text-end">
                                        {
                                            ShowIndex ? (
                                                <button className="btn btn-outline-primary" onClick={() => setShowIndex(ShowIndex - 1)}>{'<'}</button>
                                            ) : null
                                        }

                                        {
                                            Array.from({ length: tableLen }, (_, index) => (
                                                <button key={index} className="btn btn-outline-primary" onClick={() => setShowIndex(index)}>{index + 1}</button>
                                            ))
                                        }

                                        {
                                            tableLen > ShowIndex + 1 ? (
                                                <button className="btn btn-outline-primary" onClick={() => setShowIndex(ShowIndex + 1)}>{'>'}</button>
                                            ) : null
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageStudents;